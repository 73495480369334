import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {FormGroup, Input, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {useForm} from "react-hook-form";

export default function InvoiceAddLineModal(props) {
    const {register, handleSubmit, errors, setValue} = useForm();
    const [quantityUnit, setQuantityUnit] = useState([
        {
            key: 1,
            label: "J",
            value: "J"
        }
    ]);
    const [discountUnit, setDiscountUnit] = useState([
        {
            key: 1,
            label: "J",
            value: "J"
        }
    ]);

    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = data => {
        console.log("in modal submit", data);
        if (data.price !== undefined && data.price !== "0" && data.price !== "0" && data.quantity !== undefined && data.quantity !== "0" && data.quantity !== "0") {
            props.processAction(data);
            props.onHide();
            setErrorMessage("");
        } else {
            setErrorMessage("Ligne erroné");
        }
    };

    useEffect(() => {
        register({name: "quantityUnit"});
        setValue("quantityUnit", "J");
        register({name: "discountUnit"});
        setValue("discountUnit", "J");
        setValue("quantity", 0.0);
        setValue("vat", 0.0);
        if (props.assignment !== undefined && props.assignment.id !== undefined) {
            console.log("in line");
            let discount =
                props.assignment.discounts !== undefined &&
                props.assignment.discounts.length > 0
                    ? props.assignment.discounts.find(discount => discount.enable)
                        .quantity
                    : 0.0;
            let price =
                props.assignment.rates !== undefined &&
                props.assignment.rates.length > 0
                    ? props.assignment.rates.find(rate => rate.enable).rate
                    : 0.0;
            console.log("in discount", discount);
            console.log("in price", price);
            setValue("price", price);
            setValue("discount", discount);
        }
    }, []);

    const getDefaultValuePrice = () => {
        return props.assignment !== undefined &&
        props.assignment.id !== undefined &&
        props.rates !== undefined &&
        props.rates.length > 0
            ? props.rates.find(rate => rate.enable).rate
            : 0.0;
    };

    const getDefaultValueDiscount = () => {
        return props.assignment !== undefined &&
        props.assignment.id !== undefined &&
        props.discounts !== undefined &&
        props.discounts.length > 0
            ? props.discounts.find(discount => discount.enable).quantity
            : 0.0;
    };

    const getDefaultValueQuantity = () => {
        return props.assignment !== undefined &&
        props.assignment.id !== undefined &&
        props.quantity !== undefined &&
        props.quantity !== null
            ? props.quantity
            : 0.0;
    };


    const handleChangeQuantityUnit = selectedOption => {
        console.log("contract.contractType", selectedOption);
        setValue("contract.contractType", selectedOption.value);
    };

    const handleChangeDiscountUnit = selectedOption => {
        console.log("discountUnit", selectedOption);
        setValue("discountUnit", selectedOption.value);
    };

    const closePopUp = () => {
        setErrorMessage("");
        props.onHide();
    };

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4 className="mb-0 fs-22 bold-text" style={{color:"#FF0B0E"}}>Ajouter une Lingne</h4>
                </Modal.Title>
            </Modal.Header>
            <div>
                <div>
                    <div className="row ma-0">
                        <div className="col-sm-12 ptb-15">
                            <div className="roe-card-style">
                                <div className="roe-card-body col-sm-12">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col className="col-sm-3">
                                                <FormGroup>
                                                    <IntlMessages id="client.designation"/>
                                                    <AdaInputText
                                                        name="designation"
                                                        errors={errors}
                                                        defaultValue={props.assignment !== undefined &&
                                                        props.assignment.id !== undefined ? props.assignment.designation : ""}
                                                        innerRef={register} style={{height:38}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="invoice.line.quantity"/>
                                                    <AdaInputText
                                                        name="quantity"
                                                        type="number"
                                                        placeholder="0.00"
                                                        step="0.01"
                                                        errors={errors}
                                                        innerRef={register} style={{height:38}}
                                                        defaultValue={getDefaultValueQuantity()}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <IntlMessages id="invoice.discount.unit"/>
                                                <AdaSelect
                                                    name="quantityUnit"
                                                    errors={errors}
                                                    defaultValue={{
                                                        key: 1,
                                                        label: "J",
                                                        value: "J"
                                                    }}
                                                    options={quantityUnit}
                                                    onChange={handleChangeQuantityUnit}
                                                    innerRef={register}
                                                />
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="invoice.line.unit.price"/>
                                                    <AdaInputText
                                                        name="price"
                                                        type="number" style={{height:38}}
                                                        placeholder="0.00"
                                                        step="0.01"
                                                        errors={errors}
                                                        defaultValue={getDefaultValuePrice()}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="invoice.line.discount"/>
                                                    <AdaInputText
                                                        name="discount" style={{height:38}}
                                                        type="number"
                                                        placeholder="0.00"
                                                        step="0.01"
                                                        errors={errors}
                                                        defaultValue={getDefaultValueDiscount()}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <IntlMessages id="invoice.discount.unit"/>
                                                <AdaSelect
                                                    name="discountUnit"
                                                    errors={errors}
                                                    defaultValue={{
                                                        key: 1,
                                                        label: "J",
                                                        value: "J"
                                                    }}
                                                    options={discountUnit}
                                                    onChange={handleChangeDiscountUnit}
                                                    innerRef={register}
                                                />
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <IntlMessages id="invoice.tva"/>
                                                    <AdaInputText
                                                        name="vat" style={{height:38}}
                                                        type="number"
                                                        placeholder="0.00"
                                                        step="0.01"
                                                        defaultValue={20}
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="pt-3">
                                            <Col className="col-sm-8">
                                                <div className="display-6 c-text-danger">
                                                    {errorMessage}
                                                </div>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <AdaButton className="c-btn c-danger w-100" onClick={() => closePopUp()}>
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>

                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <AdaButton className="c-btn c-warning w-100" type="submit">
                                                        <IntlMessages id="action.common.submit"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal.Footer/>
        </Modal>
    );
}
