import React from "react";

import {AdaButton} from "@adaming/ada-react-component";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, DOCUMENT_NAME, getHostByAppName} from "../../../../service/host";

export default function VisualizeDocumentAction({
                                                    invoice,
                                                    setErrorMessage,
                                                    context, setModalLoadingShow, 
                                                    inTable
                                                }) {

    function dataURLtoFile(dataUrl, filename, type) {

        let   bstr = atob(dataUrl),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: type});
    }

    const openDocument = (document) => {
        let file = dataURLtoFile(document.contents, document.documentName, document.documentType);
        console.log("doc", file)
        let fileURL = URL.createObjectURL(file)
        console.log("fileURL", fileURL)
        window.open(fileURL);
    }

    const callbackRes = res => {
        console.log("doc",res.data)
        openDocument(res.data);
        setModalLoadingShow(false)
    };

    const callbackError = error => {
        setErrorMessage(error.message);
        setModalLoadingShow(false)
        console.log("error to print", error.message);
    };

    const mapData = invoice =>{
        return {...invoice, 
            billingType : context === 'invoices' ? 'Facture' : 'Avoir', 
            billingDate : new Date(invoice.billingDate).toLocaleDateString("fr-FR"),
            billingPeriodEnd : new Date(invoice.billingPeriodEnd).toLocaleDateString("fr-FR"),
            billingPeriodStart : new Date(invoice.billingPeriodStart).toLocaleDateString("fr-FR"),
            paymentDueDate : new Date(invoice.paymentDueDate).toLocaleDateString("fr-FR"),
            
        }
    }
    const handlerClickVisualize = () => {
        setModalLoadingShow(true)
        console.log(JSON.stringify(invoice));
        service.put(
            getHostByAppName(DOCUMENT_NAME),
            `document/${context}/`,
            callbackRes,
            callbackError,
            mapData(invoice)
        );
    };

    return (
        <div>
            <AdaButton style={{height: 32, width: inTable? 32 : 120}} className="c-btn ma-5 no-border c-outline-primary" onClick={() => handlerClickVisualize()}>
               
               
                <i className="far fa-file-pdf" style={{fontSize: 12}}> {!inTable && ( "Télécharger")}</i>
            </AdaButton>

        </div>
    );
}
