import React from "react";
import axios from "axios";
import {getConfigObject} from "@adaming/ada-react-component";


export const getAllPersonSelect = (host, context, setState, state) => {
    let listForSelect = [];
    axios.get(`${host}/${context}/`, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item =>
                listForSelect.push({
                    key: item.id,
                    label: item.firstName + " " + item.lastName,
                    value: item.id,
                    object: item
                })
            );
            if (state !== undefined) {
                listForSelect.concat(state);
            }
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
};

export const getAllResourcesSelect = (host, context, setState, state) => {
    let listForSelect = [];
    axios.get(`${host}/${context}`, getConfigObject()).then(res => {
        if (res.data !== undefined && Array.isArray(res.data)) {
            res.data.forEach(item =>
                listForSelect.push({
                    key: item.id,
                    label: item.designation + " - " +(item.directClient && item.directClient.clientName ? item.directClient.clientName +" : " : "") + item.id,
                    value: item.id,
                    object: item
                })
            );
            if (state !== undefined) {
                listForSelect.concat(state);
            }
            setState(listForSelect)
        }

    }).then(error => {
            return error;
        }
    );
};