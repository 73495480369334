import React, {useEffect, useState} from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    Col,
    NavLink,
    Row
} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../helpers/intlMessages";
import DisplayInformation from "../../commun/displayInformation";
import InvoiceDisplayTransmitter from "../components/invoice.display.transmitter";
import {useForm} from "react-hook-form";
import InvoiceDisplayClient from "../components/invoice.display.client";
import InvoiceLines from "../components/invoice.lines";
import InvoicePayments from "../components/invoice.payments";
import InvoiceEvents from "../components/invoice.events";
import Steps from "../components/steps";
import InvoiceDocuments from "../components/invoice.documents";
import AssignmentDetails from "./assignment/assignment.details";
import * as service from "../../../service/crud.service";
import {
    ASSIGNMENT_NAME,
    BILLING_NAME,
    getHostByAppName
} from "../../../service/host";

export default function Details({hideDetails, selectedElement, context}) {
    const [activeTab, setActiveTab] = useState("1");
    const [invoice, setInvoice] = React.useState({});
    const [payments, setPayments] = useState([]);
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(
        () => {
            console.log("Hide details :", hideDetails);
            setInvoice({});
            setActiveTab("1");
            if (!hideDetails) {
                let idSelectedElement = "";
                if (selectedElement !== null && selectedElement !== undefined) {
                    idSelectedElement = selectedElement.id;
                    console.log("Id for details :", idSelectedElement);
                    service.getById(
                        getHostByAppName(BILLING_NAME),
                        `${context}`,
                        setInvoice,
                        error => console.log(error),
                        idSelectedElement
                    );

                    service.get(
                        getHostByAppName(BILLING_NAME),
                        `payments/byInvoice/${idSelectedElement}`,
                        setPayments,
                        error => console.log(error)
                    );
                }
            }
        },
        [hideDetails]
    );

    return (
        !hideDetails && (
            <div>
                <Row>
                    <Col className="col-sm-1"/>
                    <Col className="col-sm-10">
                        <Steps steps={invoice.status !== undefined ? invoice.status : []}/>
                    </Col>
                    <Col className="col-sm-1"/>
                </Row>
                <br/>
                <Nav tabs>
                    <NavItem className="roe-card-body">
                        <NavLink
                            className={classnames({
                                active: activeTab === "1"
                            })}
                            onClick={() => {
                                toggle("1");
                            }}
                        >
                            <IntlMessages id={`invoices.transmitter`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === "2"
                            })}
                            onClick={() => {
                                toggle("2");
                            }}
                        >
                            <IntlMessages id={`invoice.client`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === "3"
                            })}
                            onClick={() => {
                                toggle("3");
                            }}
                        >
                            <IntlMessages id={`invoice.lines`}/>
                        </NavLink>
                    </NavItem>
                    {context !== "assets" && (
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "4"
                                })}
                                onClick={() => {
                                    toggle("4");
                                }}
                            >
                                <IntlMessages id={`common.payment`}/>
                            </NavLink>
                        </NavItem>
                    )}
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === "5"
                            })}
                            onClick={() => {
                                toggle("5");
                            }}
                        >
                            <IntlMessages id={`common.event.history`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === "6"
                            })}
                            onClick={() => {
                                toggle("6");
                            }}
                        >
                            <IntlMessages id={`invoices.list.documents`}/>
                        </NavLink>
                    </NavItem>
                    {context !== "assets" &&
                    invoice !== undefined &&
                    invoice.assignment !== undefined &&
                    invoice.assignment !== null &&
                    invoice.assignment !== "" && (
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "7"
                                })}
                                onClick={() => {
                                    toggle("7");
                                }}
                            >
                                <IntlMessages id={`assignment`}/>
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Col sm="7">
                            <InvoiceDisplayTransmitter
                                invoice={invoice}
                                register={register}
                                setValue={setValue}
                            />
                        </Col>
                    </TabPane>
                    <TabPane tabId="2">
                        <Col sm="7">
                            <InvoiceDisplayClient
                                invoice={invoice}
                                register={register}
                                setValue={setValue}
                            />
                        </Col>
                    </TabPane>
                    <TabPane tabId="3">
                        <Col sm="14">
                            <br/>
                            <InvoiceLines
                                errors={errors}
                                register={register}
                                setValue={setValue}
                                reset={reset}
                                invoice={invoice}
                                edit={false}
                                showTotalSettled={true}
                            />
                        </Col>
                    </TabPane>
                    {context !== "assets" && (
                        <TabPane tabId="4">
                            <Col sm="14">
                                <br/>
                                <InvoicePayments payments={payments}/>
                            </Col>
                        </TabPane>
                    )}
                    <TabPane tabId="5">
                        <Col sm="14">
                            <br/>
                            <InvoiceEvents invoice={invoice}/>
                        </Col>
                    </TabPane>
                    <TabPane tabId="6">
                        <Col sm="14">
                            <br/>
                            {activeTab === "6" && (
                                <InvoiceDocuments
                                    objectResource={invoice}
                                    setObjectResource={setInvoice}
                                    context={context}
                                />
                            )}
                        </Col>
                    </TabPane>
                    {invoice !== undefined &&
                    invoice.assignment !== undefined &&
                    invoice.assignment !== null &&
                    invoice.assignment !== "" && (
                        <TabPane tabId="7">
                            <Col sm="14">
                                <br/>
                                {activeTab === "7" && (
                                    <AssignmentDetails
                                        context={"assignments/get"}
                                        selectedElement={invoice.assignment}
                                    />
                                )}
                            </Col>
                        </TabPane>
                    )}
                </TabContent>
            </div>
        )
    );
}
