import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText} from "@adaming/ada-react-component";
import * as service from "../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";

const context = "invoices";
export default function AssetDate({
                                      register,
                                      setValue,
                                      errors,
                                      invoice,
                                      edit
                                  }) {
    const [currentDate, setCurrentDate] = useState("");
    const [paymentDueDate, setPaymentDueDate] = useState("");
    const [termsOfPayment, setTermsOfPayment] = useState("");
    const [dateObject, setDateObject] = useState({});

    useEffect(() => {
        register({name: "billingDate"}, {required: "Ce champ est obligatoire."});
    }, []);

    useEffect(
        () => {
            let date = new Date();
            let dd = date.getDate();
            let mm = date.getMonth() + 1;
            let yyyy = date.getFullYear();
            if (dd < 10) {
                dd = "0" + dd;
            }
            if (mm < 10) {
                mm = "0" + mm;
            }
            let today = yyyy + "-" + mm + "-" + dd;
            console.log("currentDate", today);
            setCurrentDate(today);
        },
        [invoice]
    );

    const handlerChangeDate = (paramName, paramValue) => {
        if (paramName === "billingDate")
            getTermsOfPayment({...dateObject, billingDate: paramValue});
    };

    const getTermsOfPayment = object => {
        console.log("getTermsOfPayment ", object);
        console.log(JSON.stringify(object));
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/return_terms_of_payment`,
            callbackRes,
            callbackError,
            object
        );
    };

    const callbackRes = res => {
        setValue("termsOfPayment", res.data);
        console.log("getTermsOfPayment return", res.data);
    };

    const callbackError = error => {
        console.log("error to print", error);
    };
    return (
        <div className="ptb-5">
            <Row>
                <Col className="col-sm-4">
                    <FormGroup>
                        <IntlMessages id="asset.date"/>
                        <AdaInputText
                            type="date"
                            name="billingDate"
                            defaultValue={currentDate}
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                            onBlur={e => handlerChangeDate("billingDate", e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}
