
import React from "react";

export const  month = [
    {
        key: 1,
        label: "Janvier",
        value: "01"
    },
    {
        key: 2,
        label: "Février",
        value: "02"
    },
    {
        key: 2,
        label: "Mars",
        value: "03"
    },
    {
        key: 2,
        label: "Avril",
        value: "04"
    },
    {
        key: 2,
        label: "Mai",
        value: "05"
    },
    {
        key: 2,
        label: "Juin",
        value: "06"
    },
    {
        key: 2,
        label: "Juillet",
        value: "07"
    },
    {
        key: 2,
        label: "Août",
        value: "08"
    },
    {
        key: 2,
        label: "Septembre",
        value: "09"
    },
    {
        key: 2,
        label: "Octobre",
        value: "10"
    },
    {
        key: 2,
        label: "Novembre",
        value: "11"
    },
    {
        key: 2,
        label: "Décembre",
        value: "12"
    }
]