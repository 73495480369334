import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText} from "@adaming/ada-react-component";


export default function InvoiceNote({register, setValue, errors, invoice, edit}) {

    useEffect(() => {
        register({name: "additionalInformation"});
    }, []);

    return (
        <div>
            <Row>
                <Col >
                    <FormGroup>
                        <IntlMessages id="invoice.note"/>
                        <AdaInputText
                            name="additionalInformation"
                            defaultValue={invoice !== undefined ? invoice.additionalInformation : ""}
                            type="textarea"
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>

    );
}