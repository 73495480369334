import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {paymentMethod} from "../../commun/enum/paymentMethod";
import {useForm} from "react-hook-form";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import * as service from "../../../service/crud.service";
import {GetOrganizationIdFromSession, GetUserFromSession} from "../../../service/session.service";

const context = "invoices";
export default function InvoicePayment({
                                           invoice,
                                           setPayment,
                                           setInvoice,
                                           setModalSuccessShow,
                                           setPayments, setAssets
                                       }) {
    const [paymentTypeList, setPaymentTypeList] = useState(paymentMethod);
    const [assetList, setAssetList] = useState([]);
    const [currentDate, setCurrentDate] = useState("");
    const [showAssetList, setShowAssetList] = useState(false);
    const [disabledAmount, setDisabledAmount] = useState(false);
    const [errorMessagePayment, setErrorMessagePayment] = useState("");
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();

    useEffect(() => {
        register(
            {name: "designation"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "paymentType"},
            {required: "Ce champ est obligatoire."}
        );
        register({name: "netAmount"}, {required: "Ce champ est obligatoire."});
        register(
            {name: "paymentDate"},
            {required: "Ce champ est obligatoire."}
        );
        register({name: "codeAsset"});
        register({name: "additionalInformation"});
        let date = new Date();
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        let today = yyyy + "-" + mm + "-" + dd;
        console.log("currentDate", today);
        setCurrentDate(today);
    }, []);


    const callbackRes = data => {
        console.log("get List Asset rest service http : ", data);
        if (Array.isArray(data)) {
            data.forEach(item =>
                assetList.push({
                    key: item.id,
                    label:
                        item.billingDate +
                        " / " +
                        item.netAmount +
                        " € / " +
                        item.designation,
                    value: item.id,
                    object: item
                })
            );
        }
    };

    const handleChangePaymentType = selectedOption => {
        console.log("paymentType", selectedOption, invoice);
        setDisabledAmount(false);
        if (selectedOption !== null) {
            setValue("paymentType", selectedOption.value);
            if (selectedOption.value === "Avoir") {
                setShowAssetList(true);
                setDisabledAmount(true);
                /*List Avoir rest service */
                service.get(
                    getHostByAppName(BILLING_NAME),
                    `assets/get/${invoice.client.client}/${invoice.transmitter.organization}`,
                    callbackRes,
                    error => console.log(error)
                );
            } else {
                setValue("codeAsset", undefined);
                setShowAssetList(false);
                setAssetList([]);
            }
        } else {
            setValue("paymentType", undefined);
            setValue("codeAsset", undefined);
        }
    };

    const handleChangeAsset = selectedOption => {
        console.log("Asset", selectedOption);
        if (selectedOption !== null) {
            setValue("codeAsset", selectedOption.object.id);
            setValue("netAmount", selectedOption.object.netAmount);
            setDisabledAmount(true);
            if (selectedOption.object.netAmount > invoice.netToPay) {
                console.log(
                    "amount ",
                    selectedOption.object.netAmount,
                    invoice.netToPay
                );
                setErrorMessagePayment("depassement du reste à payer ");
            } else {
                setErrorMessagePayment("");
            }
        } else {
            setValue("codeAsset", undefined);
            setValue("netAmount", 0.0);
            setErrorMessagePayment("");
        }
    };

    const onSubmitPayment = data => {
        console.log("in submit payment", data);
        if (data.paymentType === "Avoir" && data.codeAsset === undefined) {
            setErrorMessagePayment("Merci de selectionner un avoir");
            console.log("paiement bloqué");
            return;
        }

        if (data.netAmount > invoice.netToPay) {
            setErrorMessagePayment(
                "Dépassement : Montant TTC > " + invoice.netToPay + " €"
            );
            console.log("paiement bloqué");
            return;
        }


        const fetchData = async () => {
            let userConnected = GetUserFromSession();
            const result = await service
                .putSpec(`${getHostByAppName(BILLING_NAME)}/${context}/add_payment/${invoice.id}/`, {
                    ...data,
                    netAmount: Math.round(data.netAmount),
                    user: userConnected.firstName+" "+userConnected.lastName,
                    organization:GetOrganizationIdFromSession().id
                })
                .then(res => {
                    console.log(res.data);
                    setInvoice(res.data);
                })
                .catch(function (error) {
                    setErrorMessagePayment(error.message);
                    console.log("error to print", error.message);
                });


            service.get(
                getHostByAppName(BILLING_NAME),
                `payments/byInvoice/${invoice.id}`,
                callbackResGetByPayment,
                error => console.log(error)
            );

            service.get(
                getHostByAppName(BILLING_NAME),
                `assets/get_by_invoice/${invoice.id}`,
                callbackResGetAssetPayment,
                error => console.log(error)
            );
        };
        fetchData();
    };

    const callbackResGetByPayment = data => {
        setPayments(data);
        setPayment(false);
        setModalSuccessShow(true);
        console.log("liste des payments : ", data);
    }

    const callbackResGetAssetPayment = data => {
        setAssets(data);
        console.log("liste des avoir : ", data);
    }


    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitPayment)}>
                <Row>
                    <Col>
                        <div className="display-5 c-text-danger">
                            {errorMessagePayment}{" "}
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="client.designation"/>
                            <AdaInputText
                                name="designation"
                                type="text"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="invoice.payment.type"/>
                            <AdaSelect
                                isClearable
                                name="paymentType"
                                errors={errors}
                                options={paymentTypeList}
                                onChange={handleChangePaymentType}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {showAssetList && (
                    <Row>
                        <Col>
                            <FormGroup>
                                <IntlMessages id="invoice.billing.assets.list"/>
                                <AdaSelect
                                    isClearable
                                    name="asset"
                                    errors={errors}
                                    options={assetList}
                                    onChange={handleChangeAsset}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="invoice.ttc.amount"/>
                            <AdaInputText
                                name="netAmount"
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                defaultValue={0}
                                errors={errors}
                                innerRef={register}
                                disabled={disabledAmount}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="invoice.date.paiement"/>
                            <AdaInputText
                                type="date"
                                name="paymentDate"
                                defaultValue={currentDate}
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="invoice.note"/>
                            <AdaInputText
                                name="additionalInformation"
                                type="textarea"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="col-sm-12">
                    <Col className="col-sm-8"/>
                    <Col>
                        <FormGroup>
                            <AdaButton className="c-btn c-danger w-100" onClick={() => setPayment(false)}>
                                <IntlMessages id="action.common.cancel"/>
                            </AdaButton>

                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AdaButton className="c-btn c-warning w-100" type="submit" style={{marginLeft: 10}}>
                                <IntlMessages id="action.common.submit"/>
                            </AdaButton>
                        </FormGroup>
                    </Col>
                </Row>
            </form>
        </div>
    );
}
