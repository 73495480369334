import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText} from "@adaming/ada-react-component";

export default function InvoiceObject({register, setValue, errors, invoice, edit}) {

    useEffect(() => {
        register({name: "designation"});
    }, []);

    return (
        <div className="pt-3">
            <Row>
                <Col >
                    <FormGroup>
                        <IntlMessages id="invoice.object"/>
                        <AdaInputText
                            name="designation"
                            defaultValue={invoice !== undefined ? invoice.designation : ""}
                            type="textarea"
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>

    );
}