import React, {useEffect, useState} from "react";

import {AdaButton} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import UploadDocument from "../../components/invoice.upload";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";
import {Dropdown} from "reactstrap";


export default function UploadAction({
                                         setObjectResource,
                                         setModalSuccessShow,
                                         objectResource,
                                         setErrorMessage, context
                                     }) {
    const [upload, setUpload] = useState(false);
    const {register, handleSubmit, errors, setValue} = useForm();

    const onSubmitUpload = data => {
        console.log("in submit upload document ", data);
        service.post(
            getHostByAppName(BILLING_NAME),
            `documents`,
            callbackRes,
            callbackError,
            {
                ...data, relatedTo : objectResource.id
            }
        );
    };

    const callbackRes = res => {
        console.log(res.data);
        setUpload(false);
        setModalSuccessShow(true);
    };

    const callbackError = error => {
        setErrorMessage(error.message);
        console.log("error to print", error.message);
    };

    return (
        <div>
            <AdaButton style={{height: 32, width:130}}
                       className="c-btn ma-5 no-border c-outline-primary"
                onClick={() => setUpload(true)}
            >
                <i className="far fas fa-paperclip" style={{fontSize: 12}}> Joindre un fichier</i>
            </AdaButton>

            <UploadDocument
                setUpload={setUpload}
                upload={upload}
                onSubmitUpload={onSubmitUpload}
                register={register}
                setValue={setValue}
                handleSubmit={handleSubmit}
            />
        </div>
    );
}