import React, {useEffect, useState} from "react";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import IntlMessages from "../../../helpers/intlMessages";
import {Col, FormGroup, Row} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import {month} from "../../commun/enum/month";
import * as service from "../../../service/crud.service";
import {BILLING_NAME, CRM_NAME, EMPLOYEE_NAME, getHostByAppName, ORGANIZATION_NAME} from "../../../service/host";
import * as specificService from "../../../service/employee.service";
import amountFormat from "../../commun/amount.format";
import {GetOrganizationIdFromSession} from "../../../service/session.service";

export default function BillingAdvancedSearchReportingForm({
                                                      handlerAdvancedSearch,
                                                      isAsset,
                                                      handlerCancelAdvancedSearch, amountObj
                                                  }) {
    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(true);
    const [organizationList, setOrganizationList] = useState([]);
    const [clientGroupList, setClientGroupList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState(month);
    const [employeeList, setEmployeeList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [typeSearchList, setTypeSearchList] = useState([{key:0,value:"",label:"Tout"}, {key:1,value:"INVOICE",label:"Facture"}, {key:1,value:"ASSET",label:"Avoir"}]);
    const [paymentTypeList, setPaymentType] = useState([{key:0,value:"P",label:"Payée"}, {key:1,value:"IP",label:"Impayée"}]);
    const [plusMonthList, setPlusMonthList] = useState([{key:0,value:"P3",label:"3 Mois"}, {key:1,value:"P6",label:"6 Mois"}]);
    const [contactList, setContactList] = useState([]);
    const [type, setType] = useState("");
    const [object, setObject] = useState({
        clientGroup : null,
        client: null,
        entity: null,
        month: null,
        year: null,
        paymentDueDate: null,
        billingPeriodStart: null,
        billingPeriodEnd: null,
        transmitter: GetOrganizationIdFromSession().id,
        employee: null,
        typeSearch: "",
        plusMonth: null,
        paymentType:null
    })

    useEffect(() => {
        /*List Organization Client rest service */
        //service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, setOrganizationList)
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
        service.getAllObj(getHostByAppName(CRM_NAME), `clients/groups`, setClientGroupList)
        service.getAllObj(getHostByAppName(BILLING_NAME), `billings/getYear`, setYearList)
        specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
    }, []);

    useEffect(() => {
        object.type = type
    }, [type]);


    
    const onSubmitInvoiceSearch = (data) => {
        console.log("onSubmitInvoiceSearch",{...data, ...object});
        handlerAdvancedSearch({...data, ...object});
    };

    const clear =() =>{
        reset({
            clientGroup: null,
            client: null,
            entity: null,
            month: null,
            year: null,
            paymentDueDate: null,
            billingPeriodStart: null,
            billingPeriodEnd: null,
            transmitter: GetOrganizationIdFromSession().id,
            employee: null,
            type: "",
            plusMonth: null,
            paymentType:null
        });
        setObject({
            clientGroup: null,
            client: null,
            entity: null,
            month: null,
            year: null,
            paymentDueDate: null,
            billingPeriodStart: null,
            billingPeriodEnd: null,
            transmitter: GetOrganizationIdFromSession().id,
            employee: null,
            typeSearch: "",
            plusMonth: null,
            paymentType:null
        })
        handlerCancelAdvancedSearch();
    }

    const handleChangeTypeSearch = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("typeSearch", selectedOption);
        if (selectedOption !== null) {
            setValue("typeSearch", selectedOption.key);
            setObject({...object, typeSearch: selectedOption.value})
            setType(selectedOption.value)
        } else {
            setValue("typeSearch", null);
            setObject({...object, typeSearch: null})
            setType(null)
        }
    };

    const handleChangePlusMonth = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("plusMonth", selectedOption);
        if (selectedOption !== null) {
            setValue("plusMonth", selectedOption.key);
            setObject({...object, plusMonth: selectedOption.value})
        } else {
            setValue("plusMonth", null);
            setObject({...object, plusMonth: null})
        }
    };

    const handleChangePaymentType = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("paymentType", selectedOption);
        if (selectedOption !== null) {
            setValue("paymentType", selectedOption.key);
            setObject({...object, paymentType: selectedOption.value})
        } else {
            setValue("paymentType", null);
            setObject({...object, paymentType: null})
        }
    };

    /*const handleChangeOrganization = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("transmitter", selectedOption);
        if (selectedOption !== null) {
            setValue("transmitter", selectedOption.key);
            setObject({...object, transmitter: selectedOption.key})
        } else {
            setValue("transmitter", null);
            setObject({...object, transmitter: null})
        }
    };
*/
    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("year", selectedOption);
        if (selectedOption !== null) {
            setValue("year", selectedOption.value);
            setObject({...object, year: selectedOption.value})
        } else {
            setValue("year", null);
            setObject({...object, year: null})
        }
    };

    const handleChangeMonth = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("month", selectedOption);
        if (selectedOption !== null) {
            setValue("month", selectedOption.value);
            setObject({...object, month: selectedOption.value})
        } else {
            setValue("month", null);
            setObject({...object, month: null})
        }
    };




    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("employee", selectedOption);
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
            setObject({...object, employee: selectedOption.key})
        } else {
            setValue("employee", null);
            setObject({...object, employee: null})
        }
    };

    const handleChangeClient = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("client", selectedOption);
        if (selectedOption !== null) {
            setValue("client", selectedOption.key);
            setValue("entity", null);
            setObject({...object, client: selectedOption.key, entity: null})
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${selectedOption.key}`, setEntityList)
        } else {
            setValue("client", null);
            setValue("entity", null);
            setEntityList([])
            setObject({...object, client: null, entity: null})
        }
    };

    const handleChangeClientGroup = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("clientGroup", selectedOption.key);
            setValue("client", null);
            console.log("clientGroup changed ",selectedOption.key )

            setObject({...object, clientGroup: selectedOption.key, group: null})

            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients/findByGroup/${selectedOption.key}`, setClientList)

        } else {
            setValue("clientGroup", null);
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
            setObject({...object, clientGroup: null, client: null})
        }
    };


    const handleChangeEntity = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("entity", selectedOption);
        if (selectedOption !== null) {
            setValue("entity", selectedOption.key);
            setObject({...object, entity: selectedOption.key})
        } else {
            setValue("entity", null);
            setObject({...object, entity: null})
        }
    };


    const handleChangeContact = selectedOption => {
        console.log("contact", selectedOption);
        if (selectedOption !== null) {
            setValue("billingContact", selectedOption.label);
        } else {
            setValue("billingContact", undefined);
        }
    };

    const getSelectedDefaultValueType = (typeList, selected)=>{
        return typeList.filter(elt => elt.value === selected);
      }

    return (
<>
    <form onSubmit={handleSubmit(onSubmitInvoiceSearch)}>
        <div className="roe-card-style">
            <div className="roe-card-body">
                <fieldset>
                    <legend>
                        <div className="display-3 grey--text">
                            <IntlMessages id="common.advanced.research"/>

                            {!advancedSearchShow && (
                                <i
                                    className="fas fa-search-plus"
                                    style={{fontSize: 25, paddingLeft: 15}}
                                    onClick={() =>
                                        setAdvancedSearchShow(!advancedSearchShow)
                                    }
                                />
                            )}
                            {advancedSearchShow && (
                                <i
                                    className="fas fa-search-minus"
                                    style={{fontSize: 25, paddingLeft: 15}}
                                    onClick={() =>
                                        setAdvancedSearchShow(!advancedSearchShow)
                                    }
                                />
                            )}
                        </div>
                    </legend>
                    {advancedSearchShow && (
                        <div>

                                <Row className="col-sm-12 pt-3">
                                    <Col className="col-sm-3">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    {" "}
                                                    <div className="grey--text">
                                                        <IntlMessages id="invoice.group.client"/>
                                                    </div>
                                                    <Controller
                                                        as={<AdaSelect name={"clientGroup"}/>}
                                                        name={"clientGroup"}
                                                        isClearable
                                                        errors={errors}
                                                        options={clientGroupList}
                                                        onChange={handleChangeClientGroup}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    {" "}
                                                    <div className="grey--text">
                                                        <IntlMessages id="invoice.client"/>
                                                    </div>
                                                    <Controller
                                                        as={<AdaSelect name={"client"}/>}
                                                        name={"client"}
                                                        isClearable
                                                        errors={errors}
                                                        options={clientList}
                                                        onChange={handleChangeClient}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    {" "}
                                                    <div className="grey--text">
                                                        <IntlMessages id="invoice.entity"/>
                                                    </div>
                                                    <Controller
                                                        as={<AdaSelect name={"entity"}/>}
                                                        name={"entity"}
                                                        isClearable
                                                        errors={errors}
                                                        options={entityList}
                                                        onChange={handleChangeEntity}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/*<Row>
                        <Col>
                            <FormGroup>
                                {" "}
                                <div className="grey--text">
                                    <IntlMessages id="invoice.contact"/>
                                </div>
                                <AdaSelect
                                    isClearable
                                    name="billingContact"
                                    errors={errors}
                                    options={contactList}
                                    onChange={handleChangeContact}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>*/}
                                    </Col>


                                    <Col className="col-sm-4 pl-5">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <div className="grey--text">
                                                        <IntlMessages id="invoice.period.search.from"/>
                                                    </div>
                                                    <Row>
                                                        <Col>
                                                            <Controller
                                                                as={<AdaSelect name={"month"}/>}
                                                                name={"month"}
                                                                isClearable
                                                                errors={errors}
                                                                options={monthList}
                                                                onChange={handleChangeMonth}
                                                                control={control}
                                                                innerRef={register}
                                                            />
                                                        </Col>
                                                        <Col className="pr-5">
                                                            <Controller
                                                                as={<AdaSelect name={"year"}/>}
                                                                name={"year"}
                                                                isClearable
                                                                errors={errors}
                                                                options={yearList}
                                                                onChange={handleChangeYear}
                                                                control={control}
                                                                innerRef={register}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-sm-11">
                                                <Row>
                                                    <Col className="col-sm-6">
                                                        <FormGroup>
                                                            <div className="grey--text">
                                                                <IntlMessages id="invoice.from.period"/>
                                                            </div>
                                                            <AdaInputText
                                                                type="date" style={{height:38}}
                                                                name="billingPeriodStart"
                                                                errors={errors}
                                                                innerRef={register}
                                                                onBlur={e => setObject({...object, billingPeriodStart: e.target.value})}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="col-sm-6">
                                                        <FormGroup>
                                                            <div className="grey--text">
                                                                <IntlMessages id="invoice.end.period"/>
                                                            </div>
                                                            <AdaInputText
                                                                type="date" style={{height:38}}
                                                                name="billingPeriodEnd"
                                                                errors={errors}
                                                                innerRef={register}
                                                                onBlur={e => setObject({...object, billingPeriodEnd: e.target.value})}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col className="col-sm-2 pl-5">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <div className="grey--text">
                                                        <IntlMessages id="billing.type"/>
                                                    </div>
                                                    <Controller
                                                        as={<AdaSelect name={"type"}/>}
                                                        name={"type"}
                                                        errors={errors}
                                                        options={typeSearchList}
                                                        onChange={handleChangeTypeSearch}
                                                        control={control}
                                                        innerRef={register}
                                                        defaultValue={getSelectedDefaultValueType(typeSearchList,type)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <div className="grey--text">
                                                        <IntlMessages id="common.collaborator"/>
                                                    </div>
                                                    <Controller
                                                        as={<AdaSelect name={"employee"}/>}
                                                        name={"employee"}
                                                        isClearable
                                                        errors={errors}
                                                        options={employeeList}
                                                        onChange={handleChangeEmployee}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-sm-3  pl-5">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <div className="grey--text">
                                                        <IntlMessages id="invoice.reference"/>
                                                    </div>
                                                    <AdaInputText
                                                        type="text" style={{height:38}}
                                                        name="reference"
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-sm-11">
                                                <FormGroup>
                                                    <div className="grey--text">
                                                        <IntlMessages id="billing.plus"/>
                                                    </div>
                                                    <Controller
                                                        as={<AdaSelect name={"plusMonth"}/>}
                                                        name={"plusMonth"}
                                                        errors={errors}
                                                        options={plusMonthList}
                                                        onChange={handleChangePlusMonth}
                                                        control={control}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>

                            <Row className=" pl-4">
                                <Col className="col-sm-3" >

                                            <FormGroup>
                                                <div className="grey--text">
                                                    <IntlMessages id="invoice.status"/>
                                                </div>
                                                <Controller
                                                    as={<AdaSelect name={"pa"}/>}
                                                    name={"paymentType"}
                                                    errors={errors}
                                                    options={paymentTypeList}
                                                    onChange={handleChangePaymentType}
                                                    control={control}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                        </Col>
                            </Row>


                        </div>
                    )}
                </fieldset>
            </div>
        </div>
        <div className="roe-card-style">
            <div className="roe-card-body">
                <Row className="pl-4">
                    <Col className="pt-4 pl-5">
                        <div className="header fs-14 demi-bold-text row" ><span className="chip">Total HT:</span><div className="pl-3 demi-bold-text fs-14" style={{color:"rgba(15, 17, 196, 1.00)"}}><span className="chip">{amountFormat(amountObj.totalHT)}</span></div></div>
                    </Col>
                    <Col className="pt-4 pl-5">
                        <div className="header fs-14 demi-bold-text row" ><span className="chip">Net à payer HT:</span> <div className="pl-3 demi-bold-text fs-14" style={{color:"rgba(15,17,196,1.00)"}}><span className="chip">{amountFormat(amountObj.netToPayHT)}</span></div></div>
                    </Col>
                    <Col className="col-sm-3">

                    </Col>
                </Row>

                <Row className="pl-4">
                    <Col className="pt-4 pl-5">
                        <div className="header fs-14 demi-bold-text row" ><span className="chip">Total TTC:</span> <div className="pl-3 demi-bold-text fs-14" style={{color:"rgba(15, 17, 196, 1.00)"}}><span className="chip">{amountFormat(amountObj.total)}</span></div></div>
                    </Col>
                    <Col className="pt-4 pl-5">
                        <div className="header fs-14 demi-bold-text row" ><span className="chip">Net à payer TTC:</span><div className="pl-3 demi-bold-text fs-14" style={{color:"rgba(15,17,196,1.00)"}}><span className="chip">{amountFormat(amountObj.netToPay)}</span></div></div>
                    </Col>
                    <Col className="col-sm-3">
                        <Row>
                            <Col className="pt-4 pl-5">
                                <FormGroup>
                                    <AdaButton className="c-danger col-xl-5" onClick={()=>clear()}>
                                        <IntlMessages id="action.common.cancel"/>
                                    </AdaButton>
                                    <AdaButton
                                        className="c-warning col-xl-5"
                                        type="submit"
                                        style={{marginLeft: 10}}
                                    >
                                        <IntlMessages id="common.search"/>
                                    </AdaButton>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
                        </form>


</>




    );
}
