import React, {useState} from "react";
import {Col, FormGroup, Row} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router";
import {useForm} from "react-hook-form";
import InvoiceObject from "../../components/invoice.object";
import InvoiceLines from "../../components/invoice.lines";
import InvoiceNote from "../../components/invoice.note";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import InvoiceDisplayTransmitter from "../../components/invoice.display.transmitter";
import InvoiceDisplayClient from "../../components/invoice.display.client";
import Toast from "react-bootstrap/Toast";
import ToastBody from "react-bootstrap/ToastBody";
import CustomInput from "reactstrap/es/CustomInput";
import AssetDate from "../../components/asset.date";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";
import * as service from "../../../../service/crud.service";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const context = "invoices";

export default function AssetCreateFromInvoiceForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();
    const [isValid, setIsValid] = useState(false);
    const [invoice, setInvoice] = useState({});
    const [edit, setEdit] = useState(true);
    const [listStep, setListStep] = useState([]);
    const [cancelAmount, setCancelAmount] = useState(0.0);
    const [payments, setPayments] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingModal, setLoadingModal] = React.useState(false);


    let history = useHistory();
    const invoiceId = initInvoiceId();

    function initInvoiceId() {
        const {
            match: {params}
        } = props;
        return params.invoiceId;
    }

    const callbackResInv = data => {
        console.log("get asset by ID : ", data);
        setInvoice({
            ...data,
            lines: [],
            netAmount: 0.0,
            vatAmount: 0.0,
            totalAmount: 0.0,
            netToPay: 0.0,
            totalSettled: 0.0,
            designation:
                "Création Avoir suite à l'annulation partielle de la facture :" +
                data.code
        });
        setCancelAmount(data.netAmount);
    };

    React.useEffect(
        () => {
            if (invoiceId !== undefined) {
                const fetchData = async () => {
                    service.get(
                        getHostByAppName(BILLING_NAME),
                        `${context}/${invoiceId}`,
                        callbackResInv,
                        error => console.log(error)
                    );

                    service.get(
                        getHostByAppName(BILLING_NAME),
                        `/payments/byInvoice/${invoiceId}`,
                        (data)=>setPayments(data),
                        error => console.log(error)
                    );

                };
                fetchData();
            }
        },
        [invoiceId]
    );

    const cancelCreateInvoice = () => {
        history.push(`/${context}/${invoiceId}/edit`);
    };

    function calculCancelAmount() {
        let amount = cancelAmount;
        if (payments !== undefined && payments.length > 0) {
            payments.forEach(payment => {
                amount = Math.round(amount - payment.netAmount);
            });
        }

        return amount;
    }

    const onSubmitInvoice = data => {
        console.log("isValid",isValid)
        if (isValid) {
            if (data.netAmount > calculCancelAmount()) {
                setErrorMessage(
                    "Montant invalide, montant maximal : " + calculCancelAmount() + "€"
                );
                return;
            }

            if (data.netAmount === 0 || data.netAmount === undefined) {
                setErrorMessage(
                    "Montant invalide, montant doit etre supérieur strictement à 0 € "
                );
                return;
            }
            setLoadingModal(true)
            console.log("Save asset : ", mapData(data));
            console.log(JSON.stringify(mapData(data)));
            service.put(
                getHostByAppName(BILLING_NAME),
                `${context}/cancel_part/${invoice.id}`,
                callbackRes,
                callbackError,
                mapData(data)
            );
        } else {
            console.log("is not valid");
        }
    };

    const callbackRes = res => {
        console.log(res.data);
        setLoadingModal(false)
        console.log("redirect to asset consultation", res.data);
        history.push(`/assets/${res.data.id}/edit`);
    };

    const callbackError = error => {
        setErrorMessage(error.response.data.message)
        setLoadingModal(false)
        console.log("error to print", error.message);
    };

    const mapData = data => {
        console.log("asset data from invoice", data);
        return {
            ...data,
            id: undefined,
            status: [],
            client: {
                client: data.client.client,
                entity: data.client.entity && data.client.entity.id ? data.client.entity.id : null,
                name: data.client.name,
                entityName: data.client.entity && data.client.entity.id ? data.client.entity.name : null,
                bankDetails: data.client.bankDetails,
                contact: data.client.contact && data.client.contact.id ? data.client.contact : null,
                addressBilling: data.client.addressBilling,
                administrationInformation: data.client.administrationInformation,
                contactInformation: data.client.contactInformation !== undefined && data.client.contactInformation !== null ? data.client.contactInformation : null
            },
            transmitter: {
                organization: data.transmitter.organization,
                name: data.transmitter.name,
                address:
                    data.transmitter.address !== undefined
                        ? data.transmitter.address
                        : null,
                bankDetails: data.transmitter.bankDetails,
                contactInformation: data.transmitter.contactInformation,
                administrationInformation: data.transmitter.administrationInformation
            }
        };
    };

    return (
        <div>
            <div>
                <Row>
                    <Col className="col-sm-12">
                        <div>
                            <h4 className="mb-0 fs-15 bold-text" style={{color:"#FF0B0E"}}>Création avoir suite à l'annulation partielle de la facture N° {invoice.code}</h4>
                        </div>
                    </Col>
                </Row>

                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body col-sm-12">
                                <br/>
                                <form onSubmit={handleSubmit(onSubmitInvoice)}>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <div className="display-5 c-text-danger">
                                                {errorMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <AssetDate
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                                edit={false}
                                            />

                                            <InvoiceDisplayTransmitter
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                        <Col className="col-sm-6">
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceDisplayClient
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceObject
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceLines
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit} showTotalSettled={false}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceNote
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                        <Col sm={{size: 3, order: 2, offset: 0}}>
                                            <Toast>
                                                <ToastBody>
                                                    <CustomInput
                                                        type="switch"
                                                        id="penaltyRate"
                                                        name="penaltyRate"
                                                        defaultChecked={invoice.penaltyRate}
                                                        innerRef={register}
                                                        label={<IntlMessages id="asset.penaltyRate"/>}
                                                        disabled={!edit}
                                                    />
                                                    <IntlMessages id="asset.penaltyRate.text"/>
                                                </ToastBody>
                                            </Toast>
                                        </Col>
                                        <Col sm={{size: 3, order: 3, offset: 0}}>
                                            <Toast>
                                                <ToastBody>
                                                    <CustomInput
                                                        type="switch"
                                                        id="collectionCosts"
                                                        name="collectionCosts"
                                                        defaultChecked={invoice.collectionCosts}
                                                        innerRef={register}
                                                        label={<IntlMessages id="asset.collectionCosts"/>}
                                                        disabled={!edit}
                                                    />
                                                    <IntlMessages id="asset.collectionCosts.text"/>
                                                </ToastBody>
                                            </Toast>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-sm-8"/>
                                        <Col>
                                            {edit && (
                                                <FormGroup>
                                                    <AdaButton className="c-btn c-danger w-100"  onClick={() => cancelCreateInvoice()}>
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>

                                                </FormGroup>
                                            )}
                                        </Col>
                                        <Col>
                                            {edit && (
                                                <FormGroup>

                                                    <AdaButton className="c-btn c-warning w-100"
                                                               type="submit"
                                                               onClick={() => setIsValid(true)}
                                                               style={{marginLeft: 10}}
                                                    >
                                                        <IntlMessages id="action.common.submit"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            )}
                                        </Col>
                                    </Row>
                                    <Modal
                                        show={loadingModal}
                                        size="sm"
                                        centered
                                        backdrop={"static"}
                                    >
                                        <div className="align-self-center ">
                                            <Row className="pt-3">
                                                <Col>
                                                    <Spinner animation="border" color="warning" role="status"
                                                             style={{width: '5rem', height: '5rem'}}/>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3 pb-2"><Col>
                                                <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                                    Chargement ...
                                                </div>
                                            </Col></Row>
                                        </div>
                                    </Modal>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
