import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row, ButtonGroup} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import SignAction from "../invoice/actions/invoice.action.sign";
import SweetAlert from "react-bootstrap-sweetalert";
import PaymentAction from "../invoice/actions/invoice.action.payment";
import CancelAction from "../invoice/actions/invoice.action.cancel";
import DuplicateAction from "../invoice/actions/invoice.action.duplicate";
import SendAction from "../invoice/actions/invoice.action.send";
import UploadAction from "../invoice/actions/invoice.action.upload";
import VisualizeDocumentAction from "../invoice/actions/invoice.visualize.document";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

export default function InvoiceAction({
                                          setEdit,
                                          invoice,
                                          setInvoice,
                                          setErrorMessage,
                                          setPayments,
                                          context,
                                          isAsset,
                                          setAssets,
                                          reload
                                      }) {
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    const [operation, setOperation] = useState("");
    const [modalLoadingShow, setModalLoadingShow] = useState(false);


    const onConfirm = () => {
        console.log("in reload from Action components");
        reload();
        setModalSuccessShow(false);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col align-self-end">
                    <ButtonGroup className="align-items-end">
                        <div>
                            <AdaButton style={{height: 32, width:120}} className="c-btn ma-5 no-border c-outline-primary" onClick={() => setEdit(true)}>
                                <i className="fas fa-edit" style={{fontSize: 12}}> Modifier</i>
                            </AdaButton>
                        </div>
                        <VisualizeDocumentAction setModalLoadingShow={setModalLoadingShow}
                            context={context}
                            setErrorMessage={setErrorMessage}
                            invoice={invoice}
                        />
                        <SignAction setModalLoadingShow={setModalLoadingShow}
                            context={context}
                            setInvoice={setInvoice}
                            setModalSuccessShow={setModalSuccessShow}
                            invoice={invoice}
                            setOperation={setOperation}
                            setErrorMessage={setErrorMessage}
                            operation={operation}
                        />
                        <SendAction setModalLoadingShow={setModalLoadingShow}
                            context={context}
                            setInvoice={setInvoice}
                            setModalSuccessShow={setModalSuccessShow}
                            invoice={invoice}
                            setErrorMessage={setErrorMessage}
                        />
                        {!isAsset && (
                            <PaymentAction
                                setAssets={setAssets}
                                setInvoice={setInvoice}
                                setModalSuccessShow={setModalSuccessShow}
                                invoice={invoice}
                                setOperation={setOperation}
                                setErrorMessage={setErrorMessage}
                                operation={operation}
                                setPayments={setPayments}
                            />
                        )}
                        <UploadAction
                            setObjectResource={setInvoice}
                            setModalSuccessShow={setModalSuccessShow}
                            objectResource={invoice}
                            setErrorMessage={setErrorMessage}
                            context={context}
                        />
                        <DuplicateAction
                            setInvoice={setInvoice}
                            setModalSuccessShow={setModalSuccessShow}
                            invoice={invoice}
                            setOperation={setOperation}
                            setErrorMessage={setErrorMessage}
                            operation={operation}
                            context={context}
                        />
                        {!isAsset && (
                            <CancelAction
                                setInvoice={setInvoice}
                                setModalSuccessShow={setModalSuccessShow}
                                invoice={invoice}
                                setOperation={setOperation}
                                setErrorMessage={setErrorMessage}
                                operation={operation}
                            />
                        )}
                    </ButtonGroup>

                    <SweetAlert
                        success
                        show={modalSuccessShow}
                        title="Success!"
                        onConfirm={() => onConfirm()}
                        confirmBtnCssClass="sweet-alert-confirm-button"
                        cancelBtnCssClass="sweet-alert-cancle-button"
                    >
                        <IntlMessages id="action.common.succes"/>
                    </SweetAlert>

                    <Modal
                        show={modalLoadingShow}
                        size="sm"
                        centered
                        backdrop={"static"}
                    >
                        <div className="align-self-center ">
                            <Row className="pt-3">
                                <Col>
                                    <Spinner animation="border" color="warning" role="status"
                                             style={{width: '5rem', height: '5rem'}}/>
                                </Col>
                            </Row>
                            <Row className="pt-3 pb-2"><Col>
                                <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                    Chargement ...
                                </div>
                            </Col></Row>
                        </div>
                    </Modal>

                </div>
            </div>
        </div>
    );
}
