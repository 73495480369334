import React, {useState} from "react";
import {Col, FormGroup, Row} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router";
import {useForm} from "react-hook-form";
import InvoiceObject from "../../components/invoice.object";
import InvoiceLines from "../../components/invoice.lines";
import InvoiceNote from "../../components/invoice.note";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import InvoiceAction from "../../components/invoice.actions";
import Steps from "../../components/steps";
import InvoiceDisplayTransmitter from "../../components/invoice.display.transmitter";
import InvoiceDisplayClient from "../../components/invoice.display.client";
import Toast from "react-bootstrap/Toast";
import ToastBody from "react-bootstrap/ToastBody";
import CustomInput from "reactstrap/es/CustomInput";
import InvoiceDocuments from "../../components/invoice.documents";
import InvoiceEvents from "../../components/invoice.events";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";

const context = "assets";

export default function AssetEditForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();
    const [isValid, setIsValid] = useState(false);
    const [asset, setAsset] = useState({});
    const [edit, setEdit] = useState(false);
    const [listStep, setListStep] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    let history = useHistory();
    const assetId = initInvoiceId();

    function initInvoiceId() {
        const {
            match: {params}
        } = props;
        return params.assetId;
    }

    React.useEffect(
        () => {
            if (assetId !== undefined) {
                getById();
            }
        },
        [assetId]
    );

    const cancelCreateInvoice = () => {
        history.push("/assets");
    };

    const onSubmitInvoice = data => {
        if (isValid) {
            console.log("Save asset : ", mapData(data));
            console.log(JSON.stringify(mapData(data)));
            service.patch(
                getHostByAppName(BILLING_NAME),
                `${context}`,
                callbackRes,
                callbackError,
                mapData(data),assetId
            );
        } else {
            console.log("is not valid");
        }
    };

    const callbackRes = res => {
        console.log(res.data);
        setEdit(false);
        setListStep(res.data.status);
    };

    const callbackError = error => {
        setErrorMessage(error.response.data.message)
        console.log(error);
    };

    const mapData = data => {
        console.log("asset data", data);
        return {
            ...data,
            status: asset.status,
            client: {
                name: data.client.name,
                web: data.client.web,
                description: data.client.description,
                actif: data.client.actif,
                bankDetails: data.client.bankDetails,
                contact: data.client.contact,
                entity: data.client.entity,
                addressBilling: data.client.addressBilling,
                administrationInformation: data.client.administrationInformation,
                contactInformation: data.client.contactInformation
            },
            transmitter: {
                organization: data.transmitter.id,
                name: data.transmitter.name,
                web: data.transmitter.web,
                description: data.transmitter.description,
                address: data.transmitter.address,
                bankDetails: data.transmitter.bankDetails,
                contactInformation: data.transmitter.contactInformation,
                administrationInformation: data.transmitter.administrationInformation
            }
        };
    };

    const getById = () =>{
        service.getById(
            getHostByAppName(BILLING_NAME),
            `${context}`,
            setAsset, ((error)=>console.log(error)), assetId
        );
    }

    const cancelEdit = () => {
        setEdit(false);
        getById();
    };

    return (
        <div>
            <div>
                <Row>
                    <Col className="col-sm-4 pl-5 mt-1">
                        <div>
                            <h4 className="mb-0 fs-14 bold-text" style={{color:"#FF0B0E"}}>Avoir N° {asset.code}</h4>

                        </div>
                    </Col>
                    <Col >
                        <Row>
                            <Col>
                                <Steps
                                    steps={
                                        asset.status !== undefined ? asset.status : listStep
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br/>
                <Row className="col-sm-12">

                    <Col className="align-self-end">
                        {!edit && (
                            <InvoiceAction reload={cancelEdit}
                                           setEdit={setEdit}
                                           invoice={asset}
                                           context={context}
                                           setInvoice={setAsset} setErrorMessage={setErrorMessage} isAsset={true}
                            />
                        )}
                    </Col>
                </Row>

                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body col-sm-12">
                                <br/>
                                <form onSubmit={handleSubmit(onSubmitInvoice)}>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <div className="display-5 c-text-danger">
                                                {errorMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            {/*<InvoiceDate*/}
                                            {/*    errors={errors}*/}
                                            {/*    register={register}*/}
                                            {/*    setValue={setValue}*/}
                                            {/*    invoice={asset}*/}
                                            {/*    edit={edit}*/}
                                            {/*/>*/}
                                            <FormGroup>
                                                <IntlMessages id="asset.date"/>
                                                <Row>
                                                    <Col className="col-sm-5">
                                                        <AdaInputText
                                                            type="date"
                                                            name="billingDate"
                                                            defaultValue={
                                                                asset !== undefined ? asset.billingDate : ""
                                                            }
                                                            errors={errors}
                                                            innerRef={register}
                                                            disabled={!edit}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <InvoiceDisplayTransmitter
                                                register={register}
                                                setValue={setValue}
                                                invoice={asset}
                                            />
                                        </Col>
                                        <Col className="col-sm-6">
                                            <InvoiceDisplayClient
                                                register={register}
                                                setValue={setValue}
                                                invoice={asset}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceObject
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={asset}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceLines
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={asset}
                                                edit={edit}
                                                showTotalSettled={false}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceNote
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={asset}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                        <Col sm={{size: 3, order: 2, offset: 0}}>
                                            <Toast>
                                                <ToastBody>
                                                    <CustomInput
                                                        type="switch"
                                                        id="penaltyRate"
                                                        name="penaltyRate"
                                                        defaultChecked={asset.penaltyRate}
                                                        innerRef={register}
                                                        label={<IntlMessages id="asset.penaltyRate"/>}
                                                        disabled={!edit}
                                                    />
                                                    <IntlMessages id="asset.penaltyRate.text"/>
                                                </ToastBody>
                                            </Toast>
                                        </Col>
                                        <Col sm={{size: 3, order: 3, offset: 0}}>
                                            <Toast>
                                                <ToastBody>
                                                    <CustomInput
                                                        type="switch"
                                                        id="collectionCosts"
                                                        name="collectionCosts"
                                                        defaultChecked={asset.collectionCosts}
                                                        innerRef={register}
                                                        label={<IntlMessages id="asset.collectionCosts"/>}
                                                        disabled={!edit}
                                                    />
                                                    <IntlMessages id="asset.collectionCosts.text"/>
                                                </ToastBody>
                                            </Toast>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5 mt-3">
                                            <InvoiceDocuments
                                                objectResource={asset} setObjectResource={setAsset}
                                                context={context}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5 mt-3">
                                            <InvoiceEvents invoice={asset}/>
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col className="col-sm-8"/>
                                        <Col>
                                            {edit && (
                                                <FormGroup>
                                                    <AdaButton className="c-btn c-danger w-100" onClick={() => setEdit(false)}>
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>

                                                </FormGroup>
                                            )}
                                        </Col>

                                        <Col>
                                            {edit && (
                                                <FormGroup>
                                                    <AdaButton className="c-btn c-warning w-100"
                                                               type="submit"
                                                               onClick={() => setIsValid(true)}
                                                               style={{marginLeft: 10}}
                                                    >
                                                        <IntlMessages id="action.common.submit"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            )}
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
