import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";
import {formatDateToFr} from "../../../commun/date.format"

export const columnsPaymentsLoaded = [

    {
        name: <IntlMessages
            id="sidebar.billing.num"
        />,
        selector: "tecCodeInvoice",
        width : "15%",
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.tecCodeInvoice ? row.tecCodeInvoice : row.codeInvoice}</h2>
    },
    {
        name: <IntlMessages
            id="assignment.designation"
        />,
        selector: "designation",
        width: "30%",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="invoice.date.paiement"
        />,
        selector: "paymentDate",
        sortable: false,
        filtrable: true,
        cell: row =>{
           return <h2>{formatDateToFr(row.paymentDate)}</h2>
        }
    },
    {
        name: <IntlMessages
            id="invoice.ttc.amount"
        />,
        selector: "netAmount",
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.netAmount} €</h2>
    },
    {
        name: <IntlMessages
            id="invoice.payment.type"
        />,
        selector: "paymentType",
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.paymentType}</h2>
    },
    {
        name: <IntlMessages
            id="invoice.status"
        />,
        selector: "additionalInformation",
        sortable: false,
        filtrable: true,
        cell: row => <h2 style={{color: row.wrongData  === true ?"#FF0B0E":"#00bd80"}}>{row.additionalInformation}</h2>
    }

];
