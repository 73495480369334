import React, { Fragment } from "react";
import Cookies from 'universal-cookie';
import {isConnected} from "@adaming/ada-react-component";


const cookies = new Cookies();
const {REACT_APP_DOMAIN} = process.env;

export const ProtectedRoute = ({ authData, children }) => {
    return (
        <div>
            {isConnected() ? (
                <Fragment>{children}</Fragment>
            ) : (
                window.open('https://portal' + '.' + REACT_APP_DOMAIN + '/login', "_parent")
            )}
        </div>
    );
};
