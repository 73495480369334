/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AdaButton, AdaSelect, AdaTable, getOrganizationSelected} from "@adaming/ada-react-component";
import InvoiceUploadDocument from "../../components/invoice.upload";
import {Controller, useForm} from 'react-hook-form';
import axios from "axios";
import IntlMessages from "../../../../helpers/intlMessages";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, CRM_NAME, DOCUMENT_NAME, getHostByAppName, ORGANIZATION_NAME} from "../../../../service/host";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FormGroup} from "reactstrap";
import {columnsPayments} from "../data/columns";
import {columnsPaymentsLoaded} from "../data/columnsLoaded";
import {formatFrDateForApi, formatDateToFr} from "../../../commun/date.format"
import SweetAlert from "react-bootstrap-sweetalert";

const context = "payments";
export default function ImportForm(props) {

    let history = useHistory();
    let defaultValues = {};
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        control,
        unregister
    } = useForm();
    const [options, setOptions] = useState(
        [
            {name: "designation ", label: "Designation", value: "designation"},
            {name: "paymentType", label: "Type de Paiement", value: "paymentType"},
            {name: "netAmount", label: "Montant Net", value: "netAmount"},
            {name: "paymentDate", label: "Date de Paiement", value: "paymentDate"},
            {name: "codeInvoice", label: "N° facture", value: "codeInvoice"}
        ])
    ;

    /*documents detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    const [upload, setUpload] = useState(false);
    const [valid, setValid] = useState(false);
    const [columns, setColumns] = useState([])
    const [columnsLoaded, setColumnsLoaded] = useState([])
    const [loadedDocuments, setLoadedDocuments] = useState([])
    const [loadedData, setLoadedData] = useState([]);
    const [selectedElems, setSelectedElems] = useState([]);
    const [selectedElements, setSelectedElements] = useState({});
    const [message, setMessage] = useState("");
    let lastTable = [];
    const columnsName = ["col1","col2","col3","col4","col5","col6","col7","col8","col9","col10"];
    const columnsNames = [];
    const columnPrefix = "col";
    const [resultats, setResultats] = useState([]);
    const [document, setDocument] = useState({})
    const [object, setObject] = useState({"client": null, organization: null,group : null});
    const [clientList, setClientList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [paymentLoaded, setPaymentLoaded] = useState([]);
    const [loaded, setLoaded] = useState(false)
    const [modalErrorShow, setModalErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const find_duplicate_in_array = (arr) => {
        let results = [];
        if (undefined !== arr) {
            let sorted_arr = arr.slice().sort();
            for (let i = 0; i < sorted_arr.length - 1; i++) {
                if (sorted_arr[i + 1] === sorted_arr[i]) {
                    results.push(sorted_arr[i]);
                }
            }
        }
        return results.length;
    }

    const controleOnChangeElement = (columnName, selectedOption, columnIndex) => {
        let mess = ""
        let valide = true;
        let col = columnPrefix + columnIndex;
        selectedElems.map(elem => {
            if (selectedOption[1].name === elem[1].name) {
                selectedElems.splice(selectedElems.indexOf(elem), 1);
            }
        })

        if (null !== selectedOption[0]) {
            selectedElems.map(eme => {
                if (selectedOption[0] === eme[0] && eme[1].name !== col) {
                    valide = false;
                }
            })
            selectedElems.push(selectedOption);
        }

        if (find_duplicate_in_array(selectedElems.map(edema => { return edema[0]; })) !== 0) {
            valide = false;
            setMessage("Choix invalide");
        }

        if (selectedElems.length === 0) {
            valide = false;
        }

        return valide;
    }


    const handleChangeColumnSelect = (columnName, selectedOption, columnIndex) => {
            let contole = controleOnChangeElement(columnName, selectedOption, columnIndex);
            if (!contole) {
               setValid(false);
            } else {
                setValid(true);
            }


            let columnWhenClear = selectedElements[columnIndex];
            if (null !== selectedOption[0]) {
                Object.defineProperty(selectedElements, columnIndex, {
                    value: selectedOption[0].value,
                    writable: true,
                    configurable: true
                });
                loadedData.forEach((loaded, i) => {
                    Object.defineProperty(loaded, selectedOption[0].value, {
                        value: lastTable[i][columnName],
                        writable: true,
                        configurable: true
                    });
                })
            } else {
                if (undefined !== columnWhenClear) {
                    loadedData.forEach((loaded, i) => {
                        if (loaded[columnName] === loaded[columnWhenClear]) {
                            const colName = columnPrefix + columnIndex;
                            let objectToReset = {};
                            Object.defineProperty(selectedElements, columnIndex, {
                                value: undefined,
                                writable: true,
                                configurable: true
                            });

                            delete selectedElements[`${columnIndex}`];
                        }
                    })
                }
            }


    }

    const onSubmitUpload = data => {
        setDocument(data)
        data.lastModified = data.lastModified.toString();
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/load/`,
            callbackRes,
            callbackError,
            {
                ...data,
            }
        );
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients/groups`, handleSetGroupList)
    };
    const handleSetGroupList=(groups)=>{
        let tmp  = groups.map(g => {return {name : g.object, label : g.object, value : g.object, key : g.object}});
        console.log(tmp)
        setGroupList(tmp)
    }

    const callbackRes = res => {
        setUpload(false);
        setResultats(res.data)
    };

    const callbackError = error => {
        if (error) {
            console.log("error to print", error.message);
            setErrorMessage(error.message);
            setModalErrorShow(true);
        }
    };


    /*Initialization Invoices List */


    /*React.useEffect(
        () => {
            service.get(
                getHostByAppName(BILLING_NAME),
                `${context}`,
                (data) =>setResultats(data),
                error => console.log(error)
            );

        },
        []
    );
*/
    React.useEffect(
        () => {
            Object.values(resultats).map((result, index) => {
                    let element = result;
                    //console.log(element.length);
                    //console.log("element",element);
                    if (index === 0) {
                        let tab = [];
                        Object.entries(element).map((rez, columnIndex) => {
                            const colName = columnsName[columnIndex];
                            tab.push({
                                "name":
                                    <div className="display-6" style={{width:"200px"}}>
                                        <Controller
                                            as={<AdaSelect
                                            />}
                                            options={options}
                                            isClearable
                                            control={control}
                                            onChange={(selectedElement) => {
                                                handleChangeColumnSelect(columnsName[columnIndex], selectedElement, columnIndex)
                                            }}

                                            innerRef={register}
                                            name={colName}
                                        />
                                    </div>
                                ,
                                "selector": columnsName[columnIndex],
                                "sortable": false,
                                "filtrable": true,
                                "cell": row => {
                                    return <h2>{formatDateToFr(row[`col${columnIndex+1}`])}</h2>
                                }
                            })

                        })
                        setColumns(tab);
                    }
                        let row = [];
                        let object1 = {};
                        for (let i in element) {

                            Object.defineProperty(object1, columnsName[i], {
                                value: element[i],
                                writable: true,
                                configurable: true
                            });
                            row.push(object1);
                        }
                        lastTable.push(row[0])
                        loadedData.push(row[0]);

                })
                setLoadedData(lastTable);
                setLoadedDocuments(lastTable);
        },
        [resultats]
    );


    const mapData = data => {
        let dataas = [];
        data.map(da => {
            dataas.push({
                designation: da.designation,
                paymentType: da.paymentType,
                netAmount: parseFloat(da.netAmount),
                paymentDate: formatFrDateForApi(da.paymentDate),
                codeInvoice: da.codeInvoice
            })
        });
        return dataas
    }

    const handleChangeClient = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("client", selectedOption.key);
            setObject({...object, client: selectedOption.key})
        } else {
            setValue("client", null);
            setObject({...object, client: null})
        }
    };

    const handleChangeGroup = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("group", selectedOption.key);
            setObject({...object, group: selectedOption.key})
        } else {
            setValue("group", null);
            setObject({...object, group: null})

        }
    };




    const onSubmitInvoice = (data) => {
        if(valid){
            let listDataToInsert = []
            loadedData.forEach(obj =>{
                let objectToInsert = {}
                Object.getOwnPropertyNames(selectedElements).forEach(propertyName=>
                    {
                        let colName = `col${parseInt(propertyName)+1}`;
                        Object.defineProperty(objectToInsert, selectedElements[propertyName], {value: obj[`${colName}`], writable: true, configurable: true});
                    }
                )
                listDataToInsert.push(objectToInsert)
            })
            if(object.client !== null && object.organization !== null){

            }

            service.put(
                getHostByAppName(BILLING_NAME), `${context}/import`,
                (res) => {setPaymentLoaded(res.data);setLoaded(true)},
                callbackError,
                {...object, paymentModels: mapData(listDataToInsert), document: document, organization : getOrganizationSelected().id} )
        }

    };

    return (

        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15 full-height">
                    <div className="roe-card-style">
                        {!loaded && <div className="roe-card-body">
                            <Row>
                                <Col className="col-sm-5 pl-5">
                                    <div>
                                        <span style={{fontSize: 16}}/>
                                    </div>
                                </Col>
                            </Row>
                            <br/>

                            <Row>
                                <Col>
                                    <div>
                                        <h4 className="mb-0 fs-14 bold-text" style={{color:"#FF0B0E"}}>Import des paiement</h4>
                                    </div>
                                </Col>
                                <Col className="col-sm-8"/>
                                <Col className=" mr-5">
                                    <AdaButton className="c-btn c-warning w-100"
                                               style={{marginLeft: 10}} onClick={() => setUpload(true)}
                                    >
                                        <IntlMessages id="action.impo.fil"/>
                                    </AdaButton>
                                </Col>
                            </Row>


                            <form onSubmit={handleSubmit(onSubmitInvoice)}>
                                    <div>
                                                <div className="row ma-0">
                                                    <div className="col-sm-12 ptb-15">
                                                        <div className="roe-card-style">
                                                            <div className="roe-card-body">
                                                                <div className="pt-3">
                                                                    <h4 className="mb-0 fs-12 bold-text" style={{color:"#FF0B0E"}}>{message}</h4>
                                                                </div>
                                                                <AdaTable
                                                                    columns={columns}
                                                                    data={loadedDocuments}
                                                                    pagination
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                    </div>
                                <Row >
                                    <Col className="col-sm-4 ml-4">
                                        <FormGroup>
                                            <IntlMessages id="invoice.client"/>
                                            <Controller
                                                as={<AdaSelect name={"client"}/>}
                                                name={"client"}
                                                isClearable
                                                errors={errors}
                                                options={clientList}
                                                onChange={handleChangeClient}
                                                control={control}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-sm-4">
                                        <FormGroup>
                                            <IntlMessages id="invoice.group.client"/>
                                            <Controller
                                                as={<AdaSelect name={"group"}/>}
                                                name={"group"}
                                                isClearable
                                                errors={errors}
                                                options={groupList}
                                                onChange={handleChangeGroup}
                                                control={control}
                                                innerRef={register}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-sm-1">

                                    </Col>
                                    <Col className="col-sm-2 mr-5 mt-4">
                                        <AdaButton className="c-btn c-warning w-100"
                                                   type="submit"
                                                   style={{marginLeft: 10}} disabled={!valid}
                                        >
                                            <IntlMessages id="action.send.fil"/>
                                        </AdaButton>
                                    </Col>
                                </Row>
                            </form>
                            <InvoiceUploadDocument
                                                setUpload={setUpload}
                                                upload={upload}
                                                onSubmitUpload={onSubmitUpload}
                                                register={register}
                                                setValue={setValue}
                                                handleSubmit={handleSubmit}
                                            />
                        </div>}

                        {loaded && <div className="roe-card-body">

                            <AdaTable
                                columns={columnsPaymentsLoaded}
                                data={paymentLoaded}
                                pagination
                            />

                        </div>}
                        <SweetAlert
                            show={modalErrorShow}
                            onConfirm={() => setModalErrorShow(false)}
                            confirmBtnCssClass="sweet-alert-confirm-button"
                            cancelBtnCssClass="sweet-alert-cancle-button"
                            error
                        >
                            {errorMessage}
                        </SweetAlert>
                    </div>
                </div>
            </div>
        </div>

    );
}
