import React, {useEffect, useState} from "react";
import {FormGroup, Row, Table} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import * as service from "../../../service/crud.service";

export default function Documents({objectResource, setObjectResource, context,reload}) {

    const [documents, setDocuments] = useState([]);

    useEffect(()=>{
        if(objectResource.id !== undefined){
            getAllDocuments(objectResource.id);
        }
    },[reload, objectResource])

    const getAllDocuments = (relatedTo) =>{
        service.get(getHostByAppName(BILLING_NAME), `documents/findBy/resource/${relatedTo}`, setDocuments, errorPrint)
    }

    const errorPrint = error => {
    }

    const callbackRes = res => {
        console.log(res.data);
        getAllDocuments(objectResource.id)
    };

    const callbackError = error => {
        console.log(error);
    };

    const deleteElementFromList = (documentId) => {
        service.deletes(getHostByAppName(BILLING_NAME),`documents`, callbackRes, callbackError, [documentId])
    };

    function dataURLtoFile(dataUrl, filename) {
        let arr = dataUrl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
    }

    const openDocument = (document) =>{
        let file = dataURLtoFile(document.contents,document.name);
        console.log("doc",file)
        let fileURL = URL.createObjectURL(file)
        window.open(fileURL);
    }

    return (
        <div>
            <Row>
                <Col>
                    <Row className="pt-3 pb-3">
                        <Col>
                            <div>
                                <span className="hash"># </span>
                                <IntlMessages id="invoices.list.documents"/>
                            </div>
                        </Col>
                    </Row>

                    <Table hover responsive bordered striped>


                        <tbody>
                        {documents.length > 0 &&
                        documents.map((document, key) => (
                            <tr key={key}>


                                <td>{document.uploadDate} {document.name}</td>

                                <td width="10%">
                                    <div className="c-btn ma-5 c-warning">
                                        <i className="far fa-file-pdf"  style={{fontSize: 15}} onClick={() => openDocument(document)}/>
                                    </div>

                                    <div className="c-btn ma-5 c-danger">
                                        <i className="fas fa-trash"  style={{fontSize: 15}} onClick={() => deleteElementFromList(document.id,objectResource.documents)}/>
                                    </div>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
}
