import {getEmployeeConnectedLight, getOrganizationSelected} from "@adaming/ada-react-component";


export function GetUserFromSession(){
    try {
        let empl = getEmployeeConnectedLight();
        return empl;
    } catch (error) {
        console.log(error)
        return {id:"5e171b0b079363412c83bda8", firstName:"Wajdi", lastName:"BELGUITH"}
    }
}

export function GetOrganizationIdFromSession(){
    try {
        let organization = getOrganizationSelected();
        return organization;
        /*return {
            "id": "5f72f4b5084dcb12d48d2c43",
            "enable": true,
            "externalAttributes": {},
            "createdBy": "",
            "createdDate": "2020-09-29T08:47:49.764Z",
            "lastModifiedBy": "",
            "lastModifiedDate": "2020-11-09T10:42:07.731Z",
            "name": "Adaming Conseil",
            "address": {
                "street": "rue gambetta",
                "zipCode": "92400",
                "codeCity": null,
                "number": "211",
                "city": "Courbevoie",
                "provence": "ile de france",
                "country": "France"
            },
            "bankDetails": [
                {
                    "owner": "Adaming Conseil",
                    "rib": "RIB78451252",
                    "iban": "FR762255887744112266",
                    "bic": "BIC45127845"
                }
            ],
            "contactInformation": {
                "email": "contact@adaming.frx",
                "phone": "01144557788",
                "mobile": "0686515360",
                "fax": "01144557788"
            },
            "activityArea": "5e68b95686f3996fe0e2403b",
            "web": "adaming.fr",
            "description": "Adaming Conseil",
            "actif": true,
            "affiliate": "5e21d0e4dc885d391051803b",
            "administrationInformation": {
                "siret": "SIRET",
                "rcs": "AASD455",
                "registrationNumber": "5315AZZZZ"
            },
            "entities": [
                {
                    "enable": true,
                    "externalAttributes": {},
                    "name": "Banque",
                    "actif": true
                },
                {
                    "enable": true,
                    "externalAttributes": {},
                    "name": "Assurance",
                    "actif": true
                }
            ]
        };*/
    } catch (error) {
        console.log(error)
    }
}