import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";
import dateFormat from "../../../commun/date.format";

export const columnsPayments = [

    {
        name: <IntlMessages
            id="assignment.designation"
        />,
        selector: "designation",
        width: "20%",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="invoices.num"
        />,
        selector: "invoiceCode",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="invoice.client"
        />,
        selector: "client.name",
        sortable: false,
        filtrable: true,
        format: row => (row.client ? (row.client.entityName ? row.client.name+"/"+row.client.entityName : row.client.name) : "")
    },
    {
        name: <IntlMessages
            id="invoice.date.paiement"
        />,
        selector: "paymentDate",
        sortable: false,
        filtrable: true,
        format : row =>dateFormat(row.paymentDate)

    },
    {
        name: <IntlMessages
            id="invoice.ttc.amount"
        />,
        selector: "netAmount",
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.netAmount} €</h2>
    },
    {
        name: <IntlMessages
            id="sidebar.billing.assets.num"
        />,
        selector: "codeAsset",
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.codeAsset ? row.codeAsset : "--"}</h2>
    },
    {
        name: <IntlMessages
            id="invoice.payment.type"
        />,
        selector: "codeAsset",
        sortable: false,
        filtrable: true,
        cell: row => <h2>{row.paymentType}</h2>
    }

];
