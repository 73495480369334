import React, {useEffect, useState} from "react";
import {Col, Row, FormGroup} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router";
import InvoiceDate from "../../components/invoice.date";
import {useForm} from "react-hook-form";
import InvoiceObject from "../../components/invoice.object";
import InvoiceLines from "../../components/invoice.lines";
import InvoiceNote from "../../components/invoice.note";
import {AdaButton} from "@adaming/ada-react-component";
import InvoiceAction from "../../components/invoice.actions";
import Steps from "../../components/steps";
import InvoiceDisplayTransmitter from "../../components/invoice.display.transmitter";
import InvoiceDisplayClient from "../../components/invoice.display.client";
import InvoiceEvents from "../../components/invoice.events";
import InvoicePayments from "../../components/invoice.payments";
import InvoiceDocuments from "../../components/invoice.documents";
import InvoiceAssets from "../../components/invoice.assets";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";

const context = "invoices";

export default function InvoiceEditForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();
    const [isValid, setIsValid] = useState(false);
    const [invoice, setInvoice] = useState({});
    const [edit, setEdit] = useState(false);
    const [payments, setPayments] = useState([]);
    const [assets, setAssets] = useState([]);
    const [reload, setReload] = useState(false);

    const [listStep, setListStep] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    let history = useHistory();
    const invoiceId = initInvoiceId();

    function initInvoiceId() {
        const {
            match: {params}
        } = props;
        return params.invoiceId;
    }

    React.useEffect(
        () => {
            if (invoiceId !== undefined) {
                getById();
            }
        },
        [invoiceId]
    );

    useEffect(
        () => {
            if (invoiceId !== undefined) {
                service.get(
                    getHostByAppName(BILLING_NAME),
                    `payments/byInvoice/${invoiceId}`,
                    setPayments, ((error)=>console.log(error))
                );

                service.get(
                    getHostByAppName(BILLING_NAME),
                    `assets/get_by_invoice/${invoiceId}`,
                    setAssets, ((error)=>console.log(error))
                );
            }
        },
        [invoice]
    );

    const cancelCreateInvoice = () => {
        history.push("/invoices");
    };

    const getById = () =>{
        service.getById(
            getHostByAppName(BILLING_NAME),
            `${context}/get`,
            callbackGetById, ((error)=>console.log(error)), invoiceId
        );
    }

    const callbackGetById = (data) => {
        console.log("callbackGetById",data)
        setInvoice(data)
    }

    const cancelEdit = () => {
        setEdit(false);
        getById();
    };

    const onSubmitInvoice = data => {
        if (isValid) {
            setErrorMessage("");
            if (data.netAmount === 0 || data.netAmount === undefined) {
                setErrorMessage(
                    "Montant invalide, montant doit etre supérieur strictement à 0 € "
                );
                return;
            }
            console.log("Save invoice : ", mapData(data));
            console.log(JSON.stringify(mapData(data)));
            service.patch(
                getHostByAppName(BILLING_NAME),
                `${context}`,
                callbackRes,
                callbackError,
                mapData(data),invoiceId
            );
        } else {
            console.log("is not valid");
        }
    };

    const callbackRes = res => {
        console.log(res.data);
        setInvoice(res.data);
        setEdit(false);
        setListStep(res.data.status);
    };

    const callbackError = error => {
        setErrorMessage(error.response.data.message)
        console.log(error);
    };

    const mapData = data => {
        console.log("invoice data", data);
        return {
            ...data,
            status: invoice.status,
            client: {
                name: data.client.name,
                web: data.client.web,
                description: data.client.description,
                actif: data.client.actif,
                bankDetails: data.client.bankDetails,
                contact: data.client.contact,
                entity: data.client.entity,
                addressBilling: data.client.addressBilling,
                administrationInformation: data.client.administrationInformation,
                contactInformation: data.client.contactInformation
            },
            transmitter: {
                organization: data.transmitter.organization,
                name: data.transmitter.name,
                web: data.transmitter.web,
                description: data.transmitter.description,
                address: data.transmitter.address,
                bankDetails: data.transmitter.bankDetails,
                contactInformation: data.transmitter.contactInformation,
                administrationInformation: data.transmitter.administrationInformation
            }
        };
    };

    return (
        <div>
            <div>
                <Row>
                    <Col className="col-sm-4 pl-5 mt-1">
                        <div>
                                <h4 className="mb-0 fs-14 bold-text" style={{color:"#FF0B0E"}}>Facture N° {invoice.code}</h4>
                        </div>
                    </Col>
                    <Col >
                        <Row>
                            <Col>
                                <Steps
                                    steps={
                                        invoice.status !== undefined ? invoice.status : listStep
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <br/>
                <Row className="col-sm-12">

                    <Col className="align-self-end">
                        {!edit && (
                            <InvoiceAction context={context} setAssets={setAssets}
                                setEdit={setEdit} reload={cancelEdit}
                                setInvoice={setInvoice}
                                invoice={invoice}
                                setErrorMessage={setErrorMessage}
                                setPayments={setPayments}
                            />
                        )}
                    </Col>
                </Row>

                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body col-sm-12">
                                <br/>
                                <form onSubmit={handleSubmit(onSubmitInvoice)}>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <div className="display-5 c-text-danger">
                                                {errorMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-3">
                                            <InvoiceDate
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>

                                            <InvoiceDisplayTransmitter
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                            />

                                        </Col>
                                        <Col className="col-sm-6">
                                            <InvoiceDisplayClient
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-1 pb-4 pr-5 mt-3">
                                            <InvoiceObject
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoiceLines
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit}
                                                showTotalSettled={true}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    {edit && (<hr/> )}
                                    <Row>
                                        <Col className="col-sm-8"/>
                                        <Col>
                                            {edit && (
                                                <FormGroup>
                                                    <AdaButton className="c-btn c-danger w-100" onClick={() => cancelEdit()}>
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>

                                                </FormGroup>
                                            )}
                                        </Col>
                                        <Col>
                                            {edit && (
                                                <FormGroup>
                                                    <AdaButton
                                                        type="submit" className="c-btn c-warning w-100"
                                                        onClick={() => setIsValid(true)}
                                                        style={{marginLeft: 10}}
                                                    >
                                                        <IntlMessages id="action.common.submit"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            )}
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoiceNote
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoicePayments payments={payments}/>
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoiceAssets assets={assets}/>
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoiceDocuments objectResource={invoice} setObjectResource={setInvoice}
                                                context={context}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoiceEvents invoice={invoice}/>
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
