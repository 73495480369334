import React, {useEffect, useState, useRef} from "react";
import {
    FormGroup,
    Label,
    Row,
    Toast,
    ToastBody,
    ToastHeader
} from "reactstrap";
import Col from "reactstrap/es/Col";
import DisplayInformationStyle from "../../commun/displayInformationStyle";
import {buildingEnterprise} from "../../../helpers/constants";

export default function InvoiceDisplayClient({register, setValue, invoice}) {
    useEffect(
        () => {
            register({name: "client"});
            register({name: "client.entity"});
            register({name: "client.contact"});
            register({name: "paymentType"});
            if (invoice.client !== undefined) {
                setValue("client", invoice.client);
                if (invoice.client.entity !== undefined) {
                    setValue("client.entity", invoice.client.entity);
                }
                if (invoice.client.contact !== undefined) {
                    setValue("client.contact", invoice.client.contact);
                }
            }
            if (invoice.paymentType !== undefined) {
                setValue("paymentType", invoice.paymentType);
            }
        },
        [invoice]
    );

    return (
        <div className="ptb-15">
            <Row>
                <Col className="col-sm-12">

                    {invoice.client &&
                    <DisplayInformationStyle  icon={buildingEnterprise} name={"Client"}
                                              title={invoice.client.name}
                                              inf1={{title : "Entité", value : invoice.client && invoice.client.entity  ? invoice.client.entityName : "ND"}}
                                              inf2={{title : "Adresse", value : invoice.client && invoice.client.addressBilling ?
                                                      invoice.client.addressBilling.streetNumber +" "+invoice.client.addressBilling.streetName +" " +invoice.client.addressBilling.zipCode
                                                      +" "+invoice.client.addressBilling.city +" " +invoice.client.addressBilling.country: "ND"}}

                                              inf3={{title : "Nom du contact", value : invoice && invoice.client.contact  && invoice.client.contact.id ? invoice.client.contact.firstName +" " +invoice.client.contact.lastName : "ND"}}
                                              inf7={{title : "Téléphone du contact", value : invoice && invoice.client.contact && invoice.client.contact.id ? invoice.client.contact.contactInformation.mobile +" / "+ invoice.client.contact.contactInformation.phone : "ND"}}
                                              inf4={{title : "Banque", value : invoice.client && invoice.client.bankDetails && invoice.client.bankDetails.bank? invoice.client.bankDetails.bank : "ND"}}
                                              inf5={{title : "IBAN", value : invoice.client.bankDetails && invoice.client.bankDetails.iban ? invoice.client.bankDetails.iban : "ND"}}
                                              inf6={{title : "Mode de paiement", value : invoice && invoice.paymentType ? invoice.paymentType : "ND"}}
                                              inf7={{title : "Email du contact", value : invoice && invoice.client.contact  && invoice.client.contact.id ? invoice.client.contact.contactInformation.email : "ND"}}
                    />
                    }



                </Col>
            </Row>
        </div>
    );
}
