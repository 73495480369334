import React, {useEffect, useState} from "react";

import {AdaButton} from "@adaming/ada-react-component";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";

import InvoicePayment from "../../components/invoice.payment.add";
import {Dropdown, FormGroup, Row} from "reactstrap";
import Modal from "react-bootstrap/Modal";

export default function PaymentAction({
                                          setInvoice,
                                          setModalSuccessShow,
                                          invoice,
                                          setOperation,
                                          operation, setPayments, setAssets
                                      }) {
    const [payment, setPayment] = useState(false);

    return (
        <div>
            <AdaButton style={{height: 32, width:140}}
                       className="c-btn ma-5 no-border c-outline-primary"
                onClick={() => setPayment(true)}
            >
                <i className="fas fa-euro-sign" style={{fontSize: 12}}> Ajouter un paiement</i>
            </AdaButton>

            <Modal
                show={payment}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4 className="mb-0 fs-22 bold-text" style={{color:"#FF0B0E"}}>Ajouter un paiement</h4>
                    </Modal.Title>
                </Modal.Header>
                <div>
                    <Row className="col-sm-12">
                        <Col className="col-sm-12">
                            <br/>
                            <InvoicePayment setAssets={setAssets}
                                setModalSuccessShow={setModalSuccessShow}
                                setInvoice={setInvoice}
                                invoice={invoice}
                                setPayment={setPayment} setPayments={setPayments}
                            />
                        </Col>
                    </Row>
                </div>
                <Modal.Footer/>
            </Modal>
        </div>
    );
}
