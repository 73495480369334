import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import * as specificService from "../../../service/employee.service";
import {ASSIGNMENT_NAME, CRM_NAME, EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";

export default function InvoiceEmployeeAssignment({
                                                      register,
                                                      setValue,
                                                      errors,
                                                      invoice,
                                                      edit,
                                                      transmitterId,
                                                      clientId,setAssignment,periodDate
                                                  }) {
    const [organizationList, setOrganizationList] = useState([]);
    const [bankDetailsList, setBankDetailsList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [assignmentList, setAssignmentList] = useState([]);
    const [showAssignment, setShowAssignment] = useState(false)
    const [empl, setEmpl] = useState(null)

    useEffect(() => {
        register({name: "employee"});
        register({name: "assignment"});
    }, []);

    useEffect(
            () => {
            /*List employee rest service */
                specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
        },
        [transmitterId]
    );

    useEffect(
        () => {
            /*List affectations rest service */
            //specificService.getAllResourcesSelect(getHostByAppName(ASSIGNMENT_NAME), `assignments/?beginDate=2020-04-29&endDate=2020-05-29&idCollaborator=5e171b0b079363412c83bda7`, setAssignmentList)
        },
        [clientId]
    );

    useEffect(()=>{
        if(periodDate && periodDate.billingPeriodStart && periodDate.billingPeriodEnd && showAssignment){
            specificService.getAllResourcesSelect(getHostByAppName(ASSIGNMENT_NAME), `assignments/?beginDate=${periodDate.billingPeriodStart}&endDate=${periodDate.billingPeriodEnd}&idCollaborator=${empl}`, callbackResultAss)
        }else{
            setAssignmentList([])
        }
    },[periodDate])

    const handleChangeEmployee = selectedOption => {
        console.log("employeeselectedOption", selectedOption);
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
            specificService.getAllResourcesSelect(getHostByAppName(ASSIGNMENT_NAME), `assignments/?beginDate=${periodDate.billingPeriodStart}&endDate=${periodDate.billingPeriodEnd}&idCollaborator=${selectedOption.key}`, callbackResultAss)
            setEmpl(selectedOption.key)
        } else {
            setValue("employee", undefined);
            setShowAssignment(false)
            setEmpl(null)
            setAssignment({});
        }
    };

    const callbackResultAss = data =>{
        setAssignmentList(data)
        setShowAssignment(true)
    }

    const handleChangeAssignment = selectedOption => {
        console.log("assignmentselectedOption", selectedOption);
        if (selectedOption !== null) {
            setValue("assignment", selectedOption.key);
            setAssignment(selectedOption.object)
        } else {
            setValue("assignment", undefined);
            setAssignment({});
        }
    };

    return (
        <div >

            <Row>
                <Col className="col-sm-10">
                    <FormGroup>
                        <IntlMessages id="invoice.employee"/>
                        <AdaSelect
                            isClearable
                            name="employee"
                            errors={errors}
                            options={employeeList}
                            onChange={handleChangeEmployee}
                            innerRef={register}
                            isDisabled={periodDate && periodDate.billingPeriodStart && periodDate.billingPeriodEnd ? false : true}
                        />
                    </FormGroup>
                </Col>
            </Row>
                <Row>
                    <Col className="col-sm-10">
                    <FormGroup>
                        <IntlMessages id="invoice.assignment"/>
                        <AdaSelect
                            isClearable
                            name="assignment"
                            errors={errors}
                            options={assignmentList}
                            onChange={handleChangeAssignment}
                            innerRef={register}
                            isDisabled={periodDate && periodDate.billingPeriodStart && periodDate.billingPeriodEnd && showAssignment ? false : true}
                        />
                    </FormGroup>
                </Col>
            </Row>

        </div>
    );
}
