import React from "react";

export const  actionsType = [
    {
        key: 1,
        label: "Annulation totale",
        value: "Cancel"
    },
    {
        key: 2,
        label: "Annulation totale et dupliquer",
        value: "CancelClone"
    },
    {
        key: 3,
        label: "Annuler partiellement",
        value: "CancelPart"
    },
]
