import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText} from "@adaming/ada-react-component";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import * as service from "../../../service/crud.service";

const context = "invoices";
export default function InvoiceDate({
                                        register,
                                        setValue,
                                        errors,
                                        invoice,
                                        edit,
                                        periodDate, setPeriodDate, clientTermsOfPayment
                                    }) {
    const [currentDate, setCurrentDate] = useState("");
    const [paymentDueDate, setPaymentDueDate] = useState("");
    const [termsOfPayment, setTermsOfPayment] = useState("");
    const [dateObject, setDateObject] = useState({});

    useEffect(() => {
        register(
            {name: "billingDate"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "paymentDueDate"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "termsOfPayment"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "billingPeriodStart"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "billingPeriodEnd"},
            {required: "Ce champ est obligatoire."}
        );
    }, []);

    useEffect(
        () => {
            if (invoice.id !== undefined) {
                console.log("in invoice date edit",invoice);
                setCurrentDate(invoice.billingDate);
                setPaymentDueDate(invoice.paymentDueDate);
                setTermsOfPayment(invoice.termsOfPayment);
                setDateObject({
                    billingDate: invoice.billingDate,
                    paymentDueDate: invoice.paymentDueDate,
                    termsOfPayment: invoice.termsOfPayment
                });
            } else {
                let date = new Date();
                let dd = date.getDate();
                let mm = date.getMonth() + 1;
                let yyyy = date.getFullYear();
                if (dd < 10) {
                    dd = "0" + dd;
                }
                if (mm < 10) {
                    mm = "0" + mm;
                }
                let today = yyyy + "-" + mm + "-" + dd;
                console.log("currentDate", today);
                setCurrentDate(today);
                setPaymentDueDate(today);
                setTermsOfPayment(0);
                setDateObject({
                    billingDate: today,
                    paymentDueDate: today,
                    termsOfPayment: "0"
                });
            }
        },
        [invoice]
    );

    useEffect(()=>{
        if (clientTermsOfPayment) {
            console.log("effect clientTermsOfPayment", clientTermsOfPayment)
            setValue("termsOfPayment", clientTermsOfPayment ? clientTermsOfPayment : 0);
            handlerChangeDate("termsOfPayment", clientTermsOfPayment ? clientTermsOfPayment : 0)
        }
    },[clientTermsOfPayment]);

    const handlerChangeDate = (paramName, paramValue) => {
        if (paramName === "billingDate") {
            setCurrentDate(paramValue);
            getPaymentDueDate({...dateObject, billingDate: paramValue});
        }
        if (paramName === "paymentDueDate") {
            setPaymentDueDate(paymentDueDate);
            getTermsOfPayment({...dateObject, paymentDueDate: paramValue});
        }
        if (paramName === "termsOfPayment") {
            setTermsOfPayment(termsOfPayment);
            getPaymentDueDate({...dateObject, termsOfPayment: paramValue});
        }
    };

    const getPaymentDueDate = object => {
        console.log("getPaymentDueDate ", object);
        console.log(JSON.stringify(object));
        service
            .putSpec(`${getHostByAppName(BILLING_NAME)}/${context}/return_payment_Due_Date`, object)
            .then(res => {
                setValue("paymentDueDate", res.data);
                setValue("paymentDueDate", res.data);
        setDateObject({
            ...object,
                    paymentDueDate: res.data
        });
                console.log("getPaymentDueDate return", res.data);
            });
    };

    const getTermsOfPayment = object => {
        console.log("getTermsOfPayment ", object);
        console.log(JSON.stringify( ));
        service
            .putSpec(`${getHostByAppName(BILLING_NAME)}/${context}/return_terms_of_payment`, object)
            .then(res => {
                setValue("termsOfPayment", res.data);
        setDateObject({
            ...object,
                    termsOfPayment: res.data
        });
                console.log("getTermsOfPayment return", res.data);
            });
    };

    return (
        <div className="ptb-5">
            <Row>
                <Col className="col-sm-4">
                    <FormGroup>
                        <IntlMessages id="invoice.date"/>
                        <AdaInputText
                            type="date"
                            name="billingDate"
                            defaultValue={currentDate}
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                            onBlur={e => handlerChangeDate("billingDate", e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col className="col-sm-4">
                    <FormGroup>
                        <IntlMessages id="invoice.due.date"/>
                        <AdaInputText
                            type="date"
                            name="paymentDueDate"
                            defaultValue={paymentDueDate}
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                            onBlur={e => handlerChangeDate("paymentDueDate", e.target.value)}
                        />
                    </FormGroup>
                </Col>

                <Col className="col-sm-2">
                    <FormGroup>
                        <IntlMessages id="invoice.terms.payment"/>
                        <AdaInputText
                            name="termsOfPayment"
                            type="number"
                            placeholder="0"
                            defaultValue={termsOfPayment}
                            step="1"
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                            onBlur={e => handlerChangeDate("termsOfPayment", e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="invoice.period"/>
                        <Row>
                            <Col className="col-sm-5">
                                <AdaInputText
                                    type="date"
                                    name="billingPeriodStart"
                                    defaultValue={
                                        invoice !== undefined ? invoice.billingPeriodStart : ""
                                    }
                                    errors={errors}
                                    innerRef={register}
                                    disabled={!edit}
                                    onChange={e => setPeriodDate({...periodDate ,billingPeriodStart : e.target.value})}
                                />
                            </Col>
                            <Col className="col-sm-5">
                                <AdaInputText
                                    type="date"
                                    name="billingPeriodEnd"
                                    defaultValue={
                                        invoice !== undefined ? invoice.billingPeriodEnd : ""
                                    }
                                    errors={errors}
                                    innerRef={register}
                                    disabled={!edit}
                                    onChange={e => setPeriodDate({...periodDate ,billingPeriodEnd : e.target.value})}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}
