import React, {useEffect, useState} from "react";
import {FormGroup, Row, Table} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";

export default function InvoiceAssets({assets}) {


    return (
        <div>
            <Row>
                <Col>
                    <Row className="pt-3 pb-3">
                        <Col>
                            <div>
                                <span className="hash"># </span>
                                <IntlMessages id="invoice.billing.assets.list"/>
                            </div>
                        </Col>
                    </Row>
                    <Table hover responsive bordered striped>
                        <thead align="left">

                        <tr>
                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="asset.date"/></div>
                            </th>

                            <th width="60%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="client.designation"/></div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.total.amount"/>
                                </div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.net.amount"/>
                                </div>
                            </th>


                        </tr>
                        </thead>

                        <tbody>
                        {assets !== undefined && assets !== null && assets.length > 0 &&(
                            assets.map((asset, key) => (
                                <tr key={key}>

                                    <td>{asset.billingDate}</td>
                                    <td>{asset.code} : {asset.designation}</td>
                                    <td>{asset.totalAmount}</td>
                                    <td>{asset.netAmount}</td>

                                </tr>
                            )))}
                        </tbody>
                    </Table>
                </Col>
            </Row>


        </div>
    );
}
