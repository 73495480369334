import React, {useEffect, useState} from "react";
import {FormGroup, Row, Table} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import InvoiceAddLineModal from "./invoice.modal.add.line";
import amountFormat from "../../commun/amount.format";

export default function InvoiceLines({
                                         register,
                                         setValue,
                                         errors,
                                         formState,
                                         invoice,
                                         edit, showTotalSettled, assignment, rates,
                                         discounts, quantity
                                     }) {
    const [lines, setLines] = useState([]);
    const [modalAddLineShow, setModalAddLineShow] = React.useState(false);
    const [discountUnit, setDiscountUnit] = useState([
        {
            key: 1,
            label: "%",
            value: "%"
        }
    ]);

    const [globalDiscountUnit, setGlobalDiscountUnit] = useState(0);

    const [totalAmount, setTotalAmount] = useState({
        totalTva: 0.0,
        totalHt: 0.0,
        totalTtc: 0.0,
        netToPay: 0.0,
        totalSettled: 0.0
    });

    useEffect(() => {
        register({name: "object"});
        register({name: "discount"});
        register({name: "lines"});
        setValue("discount", 0.0);

        register({name: "netAmount"});
        register({name: "vatAmount"});
        register({name: "totalAmount"});
        register({name: "netToPay"});
        register({name: "totalSettled"});

    }, []);


    useEffect(() => {
        console.log("in lines", invoice.lines)
        if (invoice.lines !== undefined) {
            setLines(invoice.lines)
            setValue("lines", invoice.lines);
        }
        if (invoice !== undefined && invoice.id !== undefined) {
            setTotalAmount({
                totalTva: invoice.vatAmount,
                totalHt: invoice.totalAmount,
                totalTtc: invoice.netAmount,
                netToPay: invoice.netToPay,
                totalSettled: invoice.totalSettled
            })
            setValue("netAmount", invoice.netAmount);
            setValue("vatAmount", invoice.vatAmount);
            setValue("totalAmount", invoice.totalAmount);
            setValue("netToPay", invoice.netToPay);
            setValue("totalSettled", invoice.totalSettled);
        }
    }, [invoice]);

    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            } else {
                calculateAmountAfterDeleteLine(value);
            }
        });
        setValue("lines", arr);
        return arr;
    };

    const handlerSaveAddLine = data => {
        console.log("data line : ", data);
        let line = {
            designation: data.designation,
            quantity: data.quantity,
            quantityUnit: data.quantityUnit,
            price: data.price,
            discount: data.discount,
            discountUnit: data.discountUnit,
            vat: data.vat,
            currency: "EUR",
            netAmount: Math.round(
                data.price * (data.quantity - data.discount)
            )
        };
        let lineToSave = lines.concat(line);
        setLines(lineToSave);
        setValue("lines", lineToSave);
        calculateAmountAfterAddLine(line);
    };

    const calculateAmountAfterAddLine = line => {
        console.log("line in calculate", line);
        let totalTva =
            totalAmount.totalTva + Math.round((line.vat / 100) * line.netAmount);
        let totalHt = totalAmount.totalHt + line.netAmount;
        let totalTtc = totalTva + totalHt;
        let netToPay = totalTtc * (1 - globalDiscountUnit / 100) - totalAmount.totalSettled;
        setAmount(totalTva, totalHt, totalTtc, netToPay);
    };

    const calculateAmountAfterDeleteLine = line => {
        console.log("line in calculate", line);
        let totalTva =
            totalAmount.totalTva - Math.round((line.vat / 100) * line.netAmount);
        let totalHt = totalAmount.totalHt - line.netAmount;
        let totalTtc = totalTva + totalHt;
        let netToPay = totalTtc * (1 - globalDiscountUnit / 100) - totalAmount.totalSettled;
        setAmount(totalTva, totalHt, totalTtc, netToPay);
    };

    const setAmount = (totalTva, totalHt, totalTtc, netToPay) => {
        setTotalAmount({
            ...totalAmount,
            totalTva: totalTva,
            totalHt: totalHt,
            totalTtc: totalTtc,
            netToPay: netToPay
        });
        setValue("netAmount", totalTtc);
        setValue("vatAmount", totalTva);
        setValue("totalAmount", totalHt);
        setValue("netToPay", netToPay);
    };

    const changeDiscount = discountGlobal => {
        setGlobalDiscountUnit(discountGlobal);
        setTotalAmount({
            ...totalAmount,
            netToPay: totalAmount.totalTtc * (1 - discountGlobal / 100)
        });
    };

    const handleChangeDiscountUnit = selectedOption => {
        console.log("discountUnit", selectedOption);
        setValue("discountUnit", selectedOption.value);
    };

    return (
        <div>
            <Row>
                <Col>
                    <Table hover responsive bordered striped>
                        <thead align="left">

                        <tr>
                            {edit && (<th width="10%"/>)}
                            <th width="40%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="client.designation"/>
                                </div>
                            </th>
                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.line.quantity"/>
                                </div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.line.unit.price"/>
                                </div>
                            </th>
                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.line.discount"/>
                                </div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.tva"/></div>
                            </th>
                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.total.amount"/>
                                </div>
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {lines !== undefined && (lines.map((line, key) => (
                            <tr key={key}>
                                {edit && (<td>

                                    <AdaButton
                                        className="c-danger"
                                        onClick={() =>
                                            setLines(deleteElementFromList(key, lines))
                                        }
                                    >
                                        <i className="fas fa-trash"/>
                                    </AdaButton>

                                </td>)}
                                <td>{line.designation}</td>
                                <td>
                                    {line.quantity} {line.quantityUnit}
                                </td>
                                <td>{amountFormat(Math.round(line.price))}</td>
                                <td>
                                    {line.discount} {line.quantityUnit}
                                </td>
                                <td>{line.vat}</td>
                                <td>{amountFormat(Math.round(line.netAmount))}</td>
                            </tr>
                        )))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col className="col-sm-7">
                    {edit && (<AdaButton
                        className="c-add-btn"
                        onClick={() => setModalAddLineShow(true)}
                    >
                        <i className="fas fa-plus"></i>
                    </AdaButton>)}{" "}
                    {edit && (<IntlMessages id="invoices.add.line"/>)}
                </Col>
                <Col>
                    {false && (
                        <Row>
                            <Col className="col-sm-3 ptb-5">
                                <IntlMessages id="invoice.discount"/>
                            </Col>
                            <Col className="col-sm-3">
                                <AdaInputText
                                    name="discount"
                                    type="text"
                                    defaultValue={0.0}
                                    errors={errors}
                                    onChange={e => changeDiscount(e.target.value)}
                                />
                            </Col>
                            <Col className="col-sm-2">
                                <AdaSelect
                                    name="discountUnit"
                                    errors={errors}
                                    defaultValue={{
                                        key: 1,
                                        label: "%",
                                        value: "%"
                                    }}
                                    options={discountUnit}
                                    onChange={handleChangeDiscountUnit}
                                    innerRef={register}
                                />
                            </Col>
                        </Row>
                    )}
                    <hr/>
                    <Row>
                        <Col className="col-sm-3">
                            <div className="display-5 c-text-secondary"><IntlMessages id="invoice.vat.amount"/></div>
                        </Col>
                        <Col className="col-sm-3">{amountFormat(totalAmount.totalTva)}</Col>
                    </Row>
                    <Row>
                        <Col className="col-sm-3 ">
                            <div className="display-5 c-text-secondary"><IntlMessages id="invoice.total.amount"/></div>
                        </Col>
                        <Col className="col-sm-3">{amountFormat(totalAmount.totalHt)}</Col>
                    </Row>
                    <Row>
                        <Col className="col-sm-3 ">
                            <div className="display-5 c-text-secondary"><IntlMessages id="invoice.net.amount"/></div>
                        </Col>
                        <Col className="col-sm-3">{amountFormat(totalAmount.totalTtc)}</Col>
                    </Row>
                    {showTotalSettled && <Row>
                        <Col className="col-sm-3 ">
                            <div className="display-5 c-text-secondary"><IntlMessages id="invoice.total.settled"/></div>
                        </Col>
                        <Col className="col-sm-3">{amountFormat(totalAmount.totalSettled)}</Col>
                    </Row>}
                    <Row>
                        <Col className="col-sm-3 ">
                            <div className="display-5 c-text-secondary"><IntlMessages id="invoice.net.to.pay"/></div>
                        </Col>
                        <Col className="col-sm-3">{amountFormat(totalAmount.netToPay)}</Col>
                    </Row>
                </Col>
            </Row>
            <InvoiceAddLineModal
                show={modalAddLineShow} rates={rates} discounts={discounts}
                onHide={() => setModalAddLineShow(false)}
                processAction={handlerSaveAddLine} quantity={quantity}
                formState={formState} assignment={assignment}
            />
        </div>
    );
}
