import React, {useEffect, useState} from "react";

import {AdaButton, AdaSelect} from "@adaming/ada-react-component";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../../helpers/intlMessages";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";
import {Dropdown} from "reactstrap";


export default function DuplicateAction({
                                            setInvoice,
                                            setModalSuccessShow,
                                            invoice,
                                            setOperation,
                                            setErrorMessage,
                                            operation, context
                                        }) {
    const [duplicate, setDuplicate] = useState(false);


    const handlerClickDuplicate = () => {
        setOperation("Duplication de la facture : " + invoice.code);
        setDuplicate(true);
    };

    const callbackRes = res => {
        console.log(res.data);
        setDuplicate(false);
        setInvoice(res.data);
        setModalSuccessShow(true);
    };

    const callbackError = error => {
        setDuplicate(false);
        setErrorMessage(error.message);
        console.log("error to print", error.message);
    };

    const onSubmitDuplicate = () => {
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/duplicate/${invoice.id}/`,
            callbackRes,
            callbackError
        );
    };

    return (
        <div>
            <AdaButton style={{height: 32, width:120}}
                       className="c-btn ma-5 no-border c-outline-primary"
                onClick={() => handlerClickDuplicate()}
            >
                <i className="far fa-copy"  style={{fontSize: 12}}> Dupliquer</i>
            </AdaButton>

            <SweetAlert
                warning
                showCancel
                show={duplicate}
                confirmBtnText="Oui"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={operation}
                onConfirm={() => onSubmitDuplicate()}
                onCancel={() => setDuplicate(false)}
            >
                <br/>
                <IntlMessages id="action.common.confirmation"/>
            </SweetAlert>
        </div>
    );
}
