import React from "react";
import {useHistory} from "react-router";
import FormGroup from "react-bootstrap/FormGroup";
import {AdaSelect} from "@adaming/ada-react-component";

const context = "payments";
export default function PaymentForm(props) {

    let history = useHistory();
    return (
        <div>
            {/*<FormGroup>*/}
            {/*    <IntlMessages*/}
            {/*        id="iam.permission.applicationModules"*/}
            {/*    />*/}
            {/*    <AdaSelect*/}
            {/*        name="applicationModule"*/}
            {/*        options={applicationModules}*/}
            {/*        onChange={handleApplicationModule}*/}
            {/*        errors={errors}*/}
            {/*        innerRef={register}*/}
            {/*    />*/}
            {/*</FormGroup>*/}
        </div>
    );
}
