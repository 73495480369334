import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";

export default function Steps(props) {
    return (
        <Row className="col-sm-11 ">
            {props.steps.map(step => (
                <Col className="col-sm-2 text-center">
                    {step.finished ? (
                        step.code === "CL" ?
                        <div style={{color: "#dc3545"}}>{step.label}</div>
                            : <div style={{color: "#00C486"}}>{step.label}</div>
                    ) : (
                        <div style={{color: "rgb(214, 212, 212)"}}>{step.label}</div>
                    )}
                    <i className="icon fas fa-arrow-alt-circle-right"/>
                </Col>
            ))}
        </Row>
    );
}
