
import filtersAction from './actions'
import {GetOrganizationIdFromSession} from "../../service/session.service";

const initState = {
    invoiceFilters: {
        client: null,
        entity: null,
        month: null,
        year: null,
        paymentDueDate: null,
        billingPeriodStart: null,
        billingPeriodEnd: null,
        transmitter:  GetOrganizationIdFromSession().id,
        employee: null,
        reference: null
    },
   paymentFilters: {
        client: null,
        entity: null,
        month: null,
        year: null,
        paymentDueDate: null,
        billingPeriodStart: null,
        billingPeriodEnd: null,
        transmitter: GetOrganizationIdFromSession().id,
        employee: null,
        reference: null
    }
}


export default function rootReducer(state = initState, action) {
    switch (action.type) {
        case filtersAction.UPDATE_FILTERS:
            return {
                ...state,
                invoiceFilters: action.filters,
            }
        case filtersAction.UPDATE_FILTERS_PAYMENT:
            return {
                ...state,
                paymentFilters: action.filters
            }
        default:
            return state
    }
}