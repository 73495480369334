import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row, Toast, ToastBody, ToastHeader} from "reactstrap";

import Col from "reactstrap/es/Col";
import * as service from "../../../service/crud.service";
import {ASSIGNMENT_NAME, EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import {buildingEnterprise} from "../../../helpers/constants";
import DisplayInformationStyle from "../../commun/displayInformationStyle";

export default function InvoiceDisplayTransmitter({
                                                      register,
                                                      setValue,
                                                      invoice
                                                  }) {
    const [assignment, setAssignment] = useState({})
    const [employee, setEmployee] = useState({})

    useEffect(
        () => {
            register({name: "transmitter"});
            register({name: "transmitter.bankDetails"});
            if (invoice.transmitter !== undefined) {
                setValue("transmitter", invoice.transmitter);
                if (invoice.transmitter.bankDetails !== undefined) {
                    setValue("transmitter.bankDetails", invoice.transmitter.bankDetails);
                }
            }

            if (invoice.assignment !== undefined && invoice.assignment !== null && invoice.assignment !== "") {
                service.getById(
                    getHostByAppName(ASSIGNMENT_NAME),
                    `assignments`,
                    setAssignment,
                    ((error) => {console.log(error)}),
                    invoice.assignment
                );
            }

            if (invoice.employee !== undefined && invoice.employee !== null && invoice.employee !== "") {
                service.getById(
                    getHostByAppName(EMPLOYEE_NAME),
                    `employees`,
                    setEmployee,
                    ((error) => {console.log(error)}),
                    invoice.employee
                );
            }
        },
        [invoice]
    );

    return (
        <div className="ptb-15">
            <Row>
                <Col className="col-sm-12">

                    {invoice.transmitter &&
                        <DisplayInformationStyle  icon={buildingEnterprise} name={"Emetteur"}
                                              title={invoice.transmitter.name}
                                              inf1={{title : "Adresse", value : invoice.transmitter && invoice.transmitter.address ?
                                                      invoice.transmitter.address.number +" "+invoice.transmitter.address.street +" " +invoice.transmitter.address.zipCode
                                                      +" "+invoice.transmitter.address.city +" " +invoice.transmitter.address.country: "ND"}}

                                              inf4={{title : "Collaborateur", value : employee && employee.id  ? employee.firstName+" "+employee.lastName : "ND"}}
                                              inf3={{title : "Ordre de mission", value : assignment && assignment.id ? assignment.id+" "+assignment.designation : "ND"}}
                                              inf2={{title : "BIC", value : invoice.transmitter.bankDetails && invoice.transmitter.bankDetails.bic  ? invoice.transmitter.bankDetails.bic : "ND"}}
                                              inf5={{title : "IBAN", value : invoice.transmitter.bankDetails && invoice.transmitter.bankDetails.iban ? invoice.transmitter.bankDetails.iban : "ND"}}
                        />
                    }



                </Col>
            </Row>
        </div>
    );
}
