import React, {useEffect, useState} from "react";
import {FormGroup, Row, Table} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";

export default function InvoicePayments({payments}) {


    return (
        <div>
            <Row>
                <Col>
                    <Row className="pt-3 pb-3">
                        <Col>
                            <div>
                                <span className="hash"># </span>
                                <IntlMessages id="common.payment"/>
                            </div>
                        </Col>
                    </Row>

                    <Table hover responsive bordered striped>
                        <thead align="left">

                        <tr>
                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="common.date"/></div>
                            </th>
                            <th width="60%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="common.wording"/></div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.payment.type"/>
                                </div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.net.amount"/>
                                </div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="common.user"/></div>
                            </th>

                        </tr>
                        </thead>

                        <tbody>
                        {payments !== undefined && payments !== null && payments.length > 0 &&(
                            payments.map((payment, key) => (
                            <tr key={key}>

                                <td>{payment.paymentDate}</td>
                                <td>{payment.designation}</td>
                                <td>{payment.paymentType}</td>
                                <td>{payment.netAmount}</td>
                                <td>{payment.createdBy}</td>

                            </tr>
                        )))}
                        </tbody>
                    </Table>
                </Col>
            </Row>


        </div>
    );
}
