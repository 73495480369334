import React, {useEffect, useState} from "react";
import Gauge from "react-svg-gauge";
import UserInfoWrapper from "./userinfo.style";
import * as service from "../../../../../service/crud.service";
import {EMPLOYEE_NAME, getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../../../service/host";
import {Line} from "react-chartjs-2";
import PageviewsChartWrapper from "../../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";

const LatestActivity = ({icon, title, email, telephone, statut, categ, func, organ, secnum, avatar=false, res }) => {

  const [companyFunction, setCompanyFunction] = useState({})
  const [companyCategory, setCompanyCategory] = useState({})
  const [organization, setOrganization] = useState({})
  const [responsible, setResponsible] = useState({});

  useEffect(()=>{
    if(categ){
      service.getById(
          getHostByAppName(NOMENCLATURE_NAME),
          `company_categorys`,
          setCompanyCategory,
          error => console.log(error), categ
      );
    }
  },[categ])

  useEffect(()=>{
    if (res) {
      service.getById(
          getHostByAppName(EMPLOYEE_NAME),
          `employees`,
          setResponsible,
          error => console.log(error),
          res
      );
    }
  },[res])

  useEffect(()=>{
    if(func){
      service.getById(
          getHostByAppName(NOMENCLATURE_NAME),
          `company_functions`,
          setCompanyFunction,
          error => console.log(error), func
      );
    }
  },[func])

  useEffect(()=>{
    if(organ){
      service.getById(
          getHostByAppName(ORGANIZATION_NAME),
          `organizations`,
          setOrganization,
          error => console.log(error), organ
      );
    }
  },[organ])


  return (
      <div>
        <PageviewsChartWrapper>
          <div className="page-view-chart-widget-card">
            <div className="header fs-20 demi-bold-text">Colaborateur</div>
            <div className="analytics mt-4">



              <UserInfoWrapper className="fill-height">
                <div className="roe-shadow-2">

                  <div className="row">
                    <div className="col-md-4 aligner-wrapper text-center">
                      <div className="profile-image mt-3">
                        <img alt={"Avatar"}
                             src={icon}
                        />
                      </div>
                      <div style={{marginTop:"10px"}}>
                        <h3 className="card-title fs-15 bold-text">{title}</h3>
                      </div>
                    </div>
                    <div className="col-md-4 legend-wrapper">
                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-12 bold-text" >{organization.name}</h4>
                        </div>
                        <small className="text-muted ml-3">Organisation</small>
                      </div>

                      <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-info mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-12 bold-text">{telephone}</h4>
                        </div>
                        <small className="text-muted ml-3">Téléphone</small>
                      </div>

                      <div className="d-flex flex-column justify-content-center border-bottom py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-info mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-12 bold-text">{secnum}</h4>
                        </div>
                        <small className="text-muted ml-3">Numéro de securité sociale</small>
                      </div>

                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-success mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-12 bold-text">{email}</h4>
                        </div>
                        <small className="text-muted ml-3">Email</small>
                      </div>
                    </div>

                    <div className="col-md-4 legend-wrapper">
                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-12 bold-text">{companyCategory && companyCategory.name ? companyCategory.name : "ND"}</h4>
                        </div>
                        <small className="text-muted ml-3">Catégorie</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-info mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-12 bold-text">{companyFunction && companyFunction.name ? companyFunction.name : "ND"}
                          </h4>
                        </div>
                        <small className="text-muted ml-3">Fonction</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center  border-bottom py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-success mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-12 bold-text">{statut}</h4>
                        </div>
                        <small className="text-muted ml-3">Statut</small>
                      </div>

                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-success mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-12 bold-text">{responsible.firstName + " " + responsible.lastName}</h4>
                        </div>
                        <small className="text-muted ml-3">Responsable</small>
                      </div>

                    </div>

                  </div>
                </div>
              </UserInfoWrapper>
            </div>
          </div>
        </PageviewsChartWrapper>
      </div>
  );
};

export default LatestActivity;
