const filtersAction = {
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    UPDATE_FILTERS_PAYMENT: 'UPDATE_FILTERS_PAYMENT',
    
    updateFilters: (data) => {
        return {
            type: filtersAction.UPDATE_FILTERS,
            filters: data
        };
    },

    updateFiltersPayment: (data) => {
        return {
            type: filtersAction.UPDATE_FILTERS_PAYMENT,
            filters: data
        };
    }
}


export default filtersAction