import React, {useEffect, useState} from "react";

import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import Col from "reactstrap/es/Col";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../../helpers/intlMessages";
import {Dropdown, FormGroup, Row} from "reactstrap";
import {actionsType} from "../../../commun/enum/invoice.cancel.actions.type";
import {useForm} from "react-hook-form";
import {func} from "prop-types";
import {useHistory} from "react-router";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";


const context = "invoices";
export default function CancelAction({
                                         setInvoice,
                                         setModalSuccessShow,
                                         invoice,
                                         setOperation,
                                         setErrorMessage,
                                         operation
                                     }) {
    const [cancel, setCancel] = useState(false);
    const [cancelType, setCancelType] = useState("");
    const [cancelAmount, setCancelAmount] = useState(0.0);
    const [cancelAmountShow, setCancelAmountShow] = useState(false);
    const [actionType, setActionType] = useState(actionsType);
    const [errorMessageCancel, setErrorMessageCancel] = useState("");
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();
    let history = useHistory();

    const handlerClickCancel = () => {
        setOperation("Annulation de la facture : " + invoice.code);
        setCancel(true);
    };


    const onSubmitCancel = () => {
        if (cancelType !== "") {
            if(cancelType === "CancelPart"){
                history.push(`/invoice/assets/${invoice.id}/new`);
            }else if(cancelType === "CancelClone"){
                history.push(`/invoices/${invoice.id}/duplicate`);
            }else{
                setCancel(false);
                setCancelAmountShow(false)
                service.put(
                    getHostByAppName(BILLING_NAME),
                    `${context}/cancel/${invoice.id}`,
                    callbackRes,
                    callbackError
                );
            }
        } else {
            setErrorMessageCancel("Merci de specifier un type d'annulation");
        }
    };

    const callbackRes = res => {
        console.log(res.data);
        setCancel(false);
        setCancelAmountShow(false)
        setInvoice(res.data);
        setModalSuccessShow(true);
        setErrorMessageCancel("");
    };

    const callbackError = error => {
        setCancel(false);
        setErrorMessage(error.message);
        console.log("error to print", error.message);
    };

    const handlerChangeCancelAmount =(value)=>{
        setCancelAmount(value);
    }

    const handleChangeAsset = selectedOption => {
        console.log("Action", selectedOption);
        if (selectedOption !== null) {
            setCancelType(selectedOption.value);
            if (selectedOption.value === "CancelPart") {
                setCancelAmountShow(true);
            } else {
                setCancelAmountShow(false);
            }
        } else {
            setCancelAmountShow(false);
            setCancelType("");
        }
    };

    const cancelHandler = ()=>{
        setCancel(false);
        setCancelAmountShow(false);
        setErrorMessageCancel("")
    }

    return (
        <div>
            <AdaButton style={{height: 32, width:120}}
                       className="c-btn ma-5 no-border c-outline-danger"
                onClick={() => handlerClickCancel()}
            >
                <i className="fas fa-ban" style={{fontSize: 12}}> Annuler</i>
            </AdaButton>

            <SweetAlert
                style={{height: 390}}
                warning
                showCancel
                show={cancel}
                confirmBtnText="Oui"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={operation}
                onConfirm={() => onSubmitCancel()}
                onCancel={() => cancelHandler()}
            >
                <IntlMessages id="action.common.confirmation"/>

                <Row className="pt-2">
                    <Col className="pt-4">
                        <FormGroup>
                            <IntlMessages id="empty"/>
                            <AdaSelect
                                isClearable
                                name="paymentType"
                                options={actionType}
                                onChange={handleChangeAsset}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                        <div className="display-5 c-text-danger">{errorMessageCancel}</div>
                        </FormGroup>
                    </Col>
                </Row>
            </SweetAlert>
        </div>
    );
}
