
import AppLocale from "../../languageProvider";
import config, { getCurrentLanguage } from "../../settings/languageConfig";

const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || "french").locale];
export default function dateFormat(date_) {
    
    return date_ ? new Date(date_).toLocaleDateString(currentAppLocale.locale) : null;
}

export const formatFrDateForApi = (date)=>{

    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const correspondance = date.match(regex);

    if (correspondance) {
        const jour = correspondance[1];
        const mois = correspondance[2];
        const annee = correspondance[3];
        return`${annee}-${mois}-${jour}`;
    }else return date;
}

export const formatDateToFr = (date)=>{
    if(!date) return "";
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const correspondance = date.match(regex);

    if (correspondance) {
        const jour = correspondance[3];
        const mois = correspondance[2];
        const annee = correspondance[1];
        return`${jour}/${mois}/${annee}`;
    }else return date;
}
