import React, {useEffect, useState} from "react";
import {Col, Row, FormGroup} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router";
import InvoiceDate from "../../components/invoice.date";
import {useForm} from "react-hook-form";
import InvoiceClient from "../../components/invoice.client";
import InvoiceTransmitter from "../../components/invoice.transmitter";
import InvoiceObject from "../../components/invoice.object";
import InvoiceLines from "../../components/invoice.lines";
import InvoiceNote from "../../components/invoice.note";
import {AdaButton} from "@adaming/ada-react-component";
import InvoiceEmployeeAssignment from "../../components/invoice.employee";
import * as service from "../../../../service/crud.service";
import {
    ASSIGNMENT_NAME,
    BILLING_NAME, CRA_NAME,
    CRM_NAME,
    getHostByAppName
} from "../../../../service/host";
import * as specificService from "../../../../service/employee.service";
import {buildingEnterprise} from "../../../../helpers/constants";
import DisplayInformationStyle from "../../../commun/displayInformationStyle";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const context = "invoices";
export default function InvoiceForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister,control
    } = useForm();
    const [isValid, setIsValid] = useState(false);
    const [invoice, setInvoice] = useState({});
    const [assignment, setAssignment] = useState({});
    const [rates, setRates] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [edit, setEdit] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [periodDate, setPeriodDate] = useState({})
    const [quantity, setQuantity] = useState(null)
    const [client, setClient] = useState({})
    const [entity, setEntity] = useState({})
    const [address, setAddress] = useState({})
    const [contact, setContact] = useState({})
    const [clientTermsOfPayment, setClientTermsOfPayment] = useState("")
    const [loadingModal, setLoadingModal] = React.useState(false);

    let history = useHistory();

    const cancelCreateInvoice = () => {
        history.push("/invoices");
    };

    useEffect(
        () => {
            if (assignment.id !== undefined) {
                service.get(
                    getHostByAppName(ASSIGNMENT_NAME),
                    `discounts/findBy/assignment/${assignment.id}`,
                    setDiscounts, ((error)=>console.log(error))
                );
                service.get(
                    getHostByAppName(ASSIGNMENT_NAME),
                    `rates/findBy/assignment/${assignment.id}`,
                    setRates, ((error)=>console.log(error))
                );

                // get CRA by collab, date and by assignment
                service.getUri(`${getHostByAppName(CRA_NAME)}/timesheetitems/?beginDate=${periodDate.billingPeriodStart}&endDate=${periodDate.billingPeriodEnd}&idAssignment=${assignment.id}`)
                    .then(result=> {
                        setQuantity(result.data);
                        console.log("Quantityyyyyyyyyyyyyyyyyy",result.data);
                    }).catch(errors=>{
                    setQuantity(null)
                })

                service.getById(getHostByAppName(CRM_NAME),"clients", setClient, (error)=>(console.log(error)), assignment.directClient.client)
                service.get(getHostByAppName(CRM_NAME), `clients/${assignment.directClient.client}/address`, setAddressToClient, ((error)=>console.log(error)));
                if(assignment.directClient.entity){
                    service.getById(getHostByAppName(CRM_NAME),"entitys", setEntity, (error)=>(console.log(error)), assignment.directClient.entity)
                }

                if(assignment.directClient.contacts && assignment.directClient.contacts.length > 0){
                    service.getById(getHostByAppName(CRM_NAME),"contacts", setContact, (error)=>(console.log(error)), assignment.directClient.contacts[0])
                }


            }else{
                setDiscounts([]);
                setRates([]);
                setQuantity(null)
            }
        },
        [assignment, periodDate.billingPeriodEnd, periodDate.billingPeriodStart]
    );

    const setAddressToClient = (address) =>{
        console.log("address Client :",address)
        let addressObj = address !== undefined && address !== null && address.length>0 ? address[0] : null;
        console.log("addressObj Client :",addressObj)
        setAddress(addressObj);
    }

    const onSubmitInvoice = data => {
        console.log("data", data)
        if (isValid) {
            if(!assignment.id && !data.client.id){
                setErrorMessage(
                    "Merci de mentionner le Client"
                );
                return;
            }

            if (data.netAmount === 0 || data.netAmount === undefined) {
                setErrorMessage(
                    "Montant invalide, montant doit etre supérieur strictement à 0 € "
                );
                return;
            }
            setLoadingModal(true)
            setErrorMessage("");
            console.log("Save invoice : ", mapData(data));
            console.log(JSON.stringify(mapData(data)));
            service.post(
                getHostByAppName(BILLING_NAME),
                `${context}`,
                callbackRes,
                callbackError,
                mapData(data)
            );
        } else {
            console.log("is not valid");
        }
    };

    const callbackRes = res => {
        console.log(res.data);
        setEdit(false);
        setLoadingModal(false)
        history.push(`/${context}/${res.data.id}/edit`);
    };

    const callbackError = error => {
        console.log("error", error);
        setLoadingModal(false)
        setErrorMessage(error.response.data.message);
        console.log("error to print", error.message);
    };

    const mapData = data => {
        console.log("invoice data", data);
        return {
            ...data,
            totalSettled: 0.0,
            status: [],
            client: {
                client: assignment.id && client.id? client.id : data.client.id,
                entity: assignment.id && entity && entity.id !== undefined ?
                    entity.id :
                    (data.client.entity !== undefined && data.client.entity !== null && data.client.entity.id !== undefined ?
                        data.client.entity.id : null),
                name: assignment.id ? client.name : data.client.name,
                entityName: assignment.id && entity && entity.id ? entity.name : (data.client.entity !== undefined && data.client.entity !== null ? data.client.entity.name : null),
                bankDetails: assignment.id && client.id ? client.bankDetails : data.client.bankDetails,
                contact: assignment.id && client.id && contact.id ? contact : (data.client.contact !== undefined && data.client.contact !== null ? data.client.contact : null),
                addressBilling: assignment.id && client.id ? address: data.client.addressBilling,
                administrationInformation: assignment.id && client.id ? client.administrationInformation : data.client.administrationInformation,
                contactInformation: assignment.id && client.id ? client.contactInformation : data.client.contactInformation
            },
            transmitter: {
                organization: data.transmitter.id,
                name: data.transmitter.name,
                address:
                    data.transmitter.address !== undefined
                        ? data.transmitter.address
                        : null,
                bankDetails: data.transmitter.bankDetails,
                contactInformation: data.transmitter.contactInformation,
                administrationInformation: data.transmitter.administrationInformation
            }
        };
    };

    return (
        <div>
            <div>
                <Row>
                    <Col className="col-sm-3">
                        <div>
                            <div className="display-4 grey--text">
                                <h4 className="mb-0 fs-22 bold-text" style={{color:"#FF0B0E"}}>Ajouter une Facture</h4>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body col-sm-12">
                                <br/>
                                <form onSubmit={handleSubmit(onSubmitInvoice)}>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <div className="display-5 c-text-danger">
                                                {errorMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col className="col-sm-6">
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-3">
                                            <InvoiceDate
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                                edit={edit} periodDate={periodDate} setPeriodDate={setPeriodDate} clientTermsOfPayment={clientTermsOfPayment}
                                            />
                                            <InvoiceTransmitter
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                                edit={edit}
                                            />
                                            <InvoiceEmployeeAssignment
                                                register={register}
                                                edit={edit}
                                                errors={errors}
                                                setValue={setValue}
                                                invoice={invoice}
                                                setAssignment={setAssignment} periodDate={periodDate}

                                            />
                                            </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                        <Col className="col-sm-6">
                                            {!assignment.id &&
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceClient control={control}
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit} setClientTermsOfPayment={setClientTermsOfPayment}
                                            />
                                                </div></PageviewsChartWrapper>
                                            }

                                            {assignment.id && client.id &&
                                            <DisplayInformationStyle  icon={buildingEnterprise} name={"Client"}
                                                                      title={client.name}
                                                                      inf2={{title : "Adresse", value : client && address ?
                                                                              address.streetNumber +" "+address.streetName +" " +address.zipCode
                                                                              +" "+address.city +" " +address.country: "ND"}}
                                                                       inf1={{title : "Entité", value : entity && entity.id  ? entity.name : "ND"}}
                                                                       inf4={{title : "Nom du contact", value : client && contact && contact.id ? contact.firstName +" " +contact.lastName : "ND"}}
                                                                       inf5={{title : "Téléphone du Contact", value : client && contact && contact.id ? contact.contactInformation.mobile +" / "+ contact.contactInformation.phone : "ND"}}
                                                                       inf3={{title : "Banque", value : client && client.id  && client.bankDetails !== undefined && client.bankDetails && client.bankDetails.bank? client.bankDetails.bank : "ND"}}
                                                                       inf7={{title : "IBAN", value : client && client.id  && client.bankDetails !== undefined && client.bankDetails && client.bankDetails.iban ? client.bankDetails.iban : "ND"}}
                                                                       /*inf6={{title : "Mode de paiement", value : invoice && invoice.paymentType ? invoice.paymentType : "ND"}}*/
                                                                       inf6={{title : "Email du Contact", value : client && contact && contact.id ? contact.contactInformation.email : "ND"}}
                                            />}


                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-1 pb-4 pr-5 mt-3">
                                                    <InvoiceObject
                                                        errors={errors}
                                                        register={register}
                                                        setValue={setValue}
                                                        reset={reset}
                                                        invoice={invoice}
                                                        edit={edit}
                                                    />
                                                </div></PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoiceLines
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit}
                                                showTotalSettled={false}
                                                assignment={assignment}
                                                rates={rates}
                                                discounts={discounts} quantity={quantity}

                                            />
                                                </div></PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3 mb-5">
                                            <InvoiceNote
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit}
                                            />
                                                </div></PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-sm-8"/>
                                        <Col>
                                            {edit && (
                                                <FormGroup>
                                                    <AdaButton
                                                        className="c-btn c-danger w-100"
                                                        onClick={cancelCreateInvoice}
                                                    >
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            )}
                                        </Col>
                                        <Col>
                                            {edit && (
                                                <FormGroup>
                                                    <AdaButton
                                                        className="c-btn c-warning w-100"
                                                        type="submit"
                                                        onClick={() => setIsValid(true)}
                                                        style={{marginLeft: 10}}
                                                    >
                                                        <IntlMessages id="action.common.submit"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            )}
                                        </Col>
                                    </Row>

                                    <Modal
                                        show={loadingModal}
                                        size="sm"
                                        centered
                                        backdrop={"static"}
                                    >
                                        <div className="align-self-center ">
                                            <Row className="pt-3">
                                                <Col>
                                                    <Spinner animation="border" color="warning" role="status"
                                                             style={{width: '5rem', height: '5rem'}}/>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3 pb-2"><Col>
                                                <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                                    Loading ...
                                                </div>
                                            </Col></Row>
                                        </div>
                                    </Modal>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
