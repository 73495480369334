import React, {useEffect, useState} from "react";

import {AdaButton} from "@adaming/ada-react-component";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../../helpers/intlMessages";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";

export default function SignAction({
                                       setInvoice,
                                       setModalSuccessShow,
                                       invoice,
                                       setOperation,
                                       setErrorMessage,
                                       operation,
                                       context, setModalLoadingShow
                                   }) {
    const [sign, setSign] = useState(false);

    const handlerClickSign = () => {
        setOperation("Signature numérique");
        setSign(true);
    };

    const callbackRes = res => {
        console.log(res.data);
        setSign(false);
        setInvoice(res.data);
        setModalLoadingShow(false)
        setModalSuccessShow(true);
    };

    const callbackError = error => {
        setErrorMessage(error.message);
        console.log("error to print", error.message);
        setModalLoadingShow(false)
    };

    const onSubmitSign = () => {
        setModalLoadingShow(true)
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/sign/${invoice.id}/`,
            callbackRes,
            callbackError
        );
    };

    return (
        <div>
            <AdaButton style={{height: 32, width:180}}
                className="c-btn ma-5 no-border c-outline-primary"
                onClick={() => handlerClickSign()}
            >
                <i className="fas fa-pen-nib" style={{fontSize: 12}}> Signature élèctronique</i>
            </AdaButton>

            <SweetAlert
                warning
                showCancel
                show={sign}
                confirmBtnText="Oui"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={operation}
                onConfirm={() => onSubmitSign()}
                onCancel={() => setSign(false)}
            >
                <IntlMessages id="action.common.confirmation"/>
            </SweetAlert>
        </div>
    );
}
