import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaSelect} from "@adaming/ada-react-component";
import * as service from "../../../service/crud.service";
import {CRM_NAME, getHostByAppName, ORGANIZATION_NAME} from "../../../service/host";
import {GetOrganizationIdFromSession} from "../../../service/session.service";

export default function InvoiceTransmitter({
                                               register,
                                               setValue,
                                               errors,
                                               invoice,
                                               edit
                                           }) {
    const [organizationList, setOrganizationList] = useState([]);
    const [bankDetailsList, setBankDetailsList] = useState([]);

    useEffect(() => {
        register(
            {name: "transmitter"}
        );
        register({name: "transmitter.bankDetails"});
        //register({name: "transmitter.address"});
        /*List Organization Client rest service */
        //service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, setOrganizationList)

        let organization = GetOrganizationIdFromSession()
        setValue("transmitter", organization);
        setValue("transmitter.bankDetails", null);
        //service.get(getHostByAppName(ORGANIZATION_NAME), `organizations/${selectedOption.object.id}/address`, setAddressToClient, ((error)=>console.log(error)));
        if (organization.bankDetails !== undefined) {
            if (organization.bankDetails.length > 0) {
                let tab = []
                organization.bankDetails.forEach(item =>
                    tab.push({
                        key: item.id,
                        label: item.owner + " " + item.iban,
                        value: item.id,
                        object: item
                    })
                );
                setBankDetailsList(tab)
            }
        }

    }, []);

    useEffect(
        () => {
            console.log("in transmitter", invoice.transmitter);
            if (invoice.transmitter !== undefined) {
                setValue("transmitter", invoice.transmitter);
            }
        },
        [invoice]
    );

    /*const setAddressToClient = (address) =>{
        let addressObj = address !== undefined && address !== null && address.length>0 ? address[0] : null;
        setValue("transmitter.address", addressObj)
    }
*/
    const handleChangeOrganization = selectedOption => {
        console.log("transmitter", selectedOption);
        if (selectedOption !== null) {
            setValue("transmitter", selectedOption.object);
            setValue("transmitter.bankDetails", null);
            //service.get(getHostByAppName(ORGANIZATION_NAME), `organizations/${selectedOption.object.id}/address`, setAddressToClient, ((error)=>console.log(error)));
            if (selectedOption.object.bankDetails !== undefined) {
                if (selectedOption.object.bankDetails.length > 0) {
                    let tab = []
                    selectedOption.object.bankDetails.forEach(item =>
                        tab.push({
                            key: item.id,
                            label: item.owner + " " + item.iban,
                            value: item.id,
                            object: item
                        })
                    );
                    setBankDetailsList(tab)
                }
            }
        } else {
            setValue("transmitter", undefined);
            setValue("transmitter.bankDetails", null);
            setBankDetailsList([]);
        }
    };

    const handleChangeBankDetails = selectedOption => {
        console.log("transmitter.bankDetails", selectedOption);
        if (selectedOption !== null) {
            setValue("transmitter.bankDetails", selectedOption.object);
        } else {
            setValue("transmitter.bankDetails", undefined);
        }
    };

    return (
        <div className="ptb-15">
            <Row>
                {/*<Col className="col-sm-5">
                    <FormGroup>
                        <IntlMessages id="invoices.transmitter"/>
                        <AdaSelect
                            isClearable
                            name="transmitter"
                            errors={errors}
                            options={organizationList}
                            onChange={handleChangeOrganization}
                            innerRef={register}
                            isDisabled={!edit}
                        />
                    </FormGroup>
                </Col>*/}
                <Col className="col-sm-10">
                    <FormGroup>
                        <IntlMessages id="common.cord.bancaires"/>
                        <AdaSelect
                            isClearable
                            name="transmitter.bankDetails"
                            errors={errors}
                            options={bankDetailsList}
                            onChange={handleChangeBankDetails}
                            innerRef={register}
                            isDisabled={!edit}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}
