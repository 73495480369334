const ASSIGNMENT_HOST = "http://localhost:8085";
const CRM_HOST = "http://localhost:8081";
const EMPLOYEE_HOST = "http://localhost:8082";
const ORGANIZATION_HOST = "http://localhost:8083";
const BILLING_HOST = "http://localhost:8084";
const DOCUMENT_HOST = "http://localhost:8091";
const NOMENCLATURE_HOST = "http://localhost:8090";
const CRA_HOST = "http://localhost:8087";

export const ASSIGNMENT_NAME = "ASSIGNMENT";
export const CRM_NAME = "CRM";
export const EMPLOYEE_NAME = "EMPLOYEE";
export const ORGANIZATION_NAME = "ORGANIZATION";
export const BILLING_NAME = "BILLING";
export const DOCUMENT_NAME = "DOCUMENT";
export const NOMENCLATURE_NAME = "NOMENCLATURE";
export const CRA_NAME = "CRA";

export const getHostByAppName = appName => {
        if (process.env.NODE_ENV === 'local') {
            switch (appName) {
                case ASSIGNMENT_NAME:
                    return ASSIGNMENT_HOST;

                case CRM_NAME:
                    return CRM_HOST;

                case EMPLOYEE_NAME:
                    return EMPLOYEE_HOST;

                case ORGANIZATION_NAME:
                    return ORGANIZATION_HOST;

                case BILLING_NAME:
                    return BILLING_HOST;

                case DOCUMENT_NAME:
                    return DOCUMENT_HOST;

                case NOMENCLATURE_NAME:
                    return NOMENCLATURE_HOST;

                case CRA_NAME:
                    return CRA_HOST;

                default :
                    return ""
            }
        } else {
            switch (appName) {
                case ASSIGNMENT_NAME:
                    return`${process.env.REACT_APP_API_URL}/asignment-service`;

                case CRM_NAME:
                    return `${process.env.REACT_APP_API_URL}/client-service`;

                case EMPLOYEE_NAME:
                    return `${process.env.REACT_APP_API_URL}/employee-service`;

                case ORGANIZATION_NAME:
                    return `${process.env.REACT_APP_API_URL}/organization-service`;

                case BILLING_NAME:
                    return `${process.env.REACT_APP_API_URL}/billing-service`;

                case DOCUMENT_NAME:
                    return `${process.env.REACT_APP_API_URL}/document-service`;

                case NOMENCLATURE_NAME:
                    return `${process.env.REACT_APP_API_URL}/nomenclature-service`;

                case CRA_NAME:
                    return `${process.env.REACT_APP_API_URL}/cra-service`;

                default :
                    return ""
            }
        }
    }
;
