import React from "react";

export const  paymentMethod = [
    {
        key: 1,
        label: "Virement",
        value: "Virement"
    },
    {
        key: 2,
        label: "Espèce",
        value: "Espèce"
    },
    {
        key: 3,
        label: "Chèque",
        value: "Chèque"
    },
    {
        key: 4,
        label: "Avoir",
        value: "Avoir"
    }
]