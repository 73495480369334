import React, {useState} from "react";
import {columnsAssets} from "./data/columns";
import {AdaFilterSubHeader, AdaTable, AdaActions} from "@adaming/ada-react-component";
import IntlMessages from "../../../helpers/intlMessages";
import Details from "../details/details";
import {useHistory, withRouter} from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import BillingAdvancedSearchForm from "../research/advanced.research";
import * as service from "../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import {GetOrganizationIdFromSession} from "../../../service/session.service";
import {Col, Row} from "reactstrap";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const context = "assets";
const Invoices = props => {
    const [assets, setAssets] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    /*assets detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    let history = useHistory();

    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);

    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.visualize`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`invoice.send.mail`}/>,
                href: "",
                actionHandler: handlerSendByMail,
                disabled: true
            }
        ];
    };

    const handlerSendByMail = () => {
        console.log("send by mail")
        setLoadingModal(true);
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/sendAll`,
            callbackResEnableDisable,
            error => {
                console.log(error);
                setLoadingModal(false)
            },
            selectedRows.map(obj => obj.id)
        );
    }
    const callbackRes = res =>{
        setAssets(res.data);
        setFiltered(res.data);
        setMaxContent(res.headers['x-content-max'])
    }

    const initList = () =>{
        handlerAdvancedSearch({transmitter: GetOrganizationIdFromSession().id})
    }


    /*Create operation List*/
    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Send Action*/
                actionsValue[1] = {...actionsValue[1], disabled: false};
                /*Activate : Activate Action*/
                //actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Disable Action*/
                //actionsValue[3] = {...actionsValue[3], disabled: false};
                if (selectedRows.length === 1) {
                    /*Activate : Edit Action*/
                    actionsValue[0] = {
                        ...actionsValue[0],
                        href: `/${context}/${selectedRows[0].id}/edit`,
                        disabled: false
                    };
                    /*show detail true*/
                    setHideDetailPanel(false);
                }
                setActionsList(actionsValue);
                console.log("actions list : ", actionsValue);
                setAdvancedSearchShow(false);
            }
        },
        [selectedRows.length]
    );

    React.useEffect(()=>{     
        handlerAdvancedSearch({organization: GetOrganizationIdFromSession()},currentPaginationPerRow, currentPage)
    },[currentPaginationPerRow,currentPage])

    const handlerActivateAction = () => {
        console.log("in Activate action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), true);
    };

    const handlerDisableAction = () => {
        console.log("in Disable action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisable(
            selectedRows.map(element => element.id),
            false
        );
    };

    const callbackResEnableDisable = () => {
        setLoadingModal(false);
        setModalSuccessShow(true);
    };

    const handlerEnableDisable = (ids, status) => {
        console.log("in handlerEnableDisable action", ids, status);
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/enable_disable_invoices`,
            callbackResEnableDisable,
            error => {
                console.log(error);
            },
            {
                status: status,
                ids: ids
            }
        );
    };

    const handlerAdvancedSearch = React.useCallback((data,size,page) => {
        if(size == null){
            size = defaultSizePerPage;
        }

        if(page == null){
            page = defaultPage;
        }
        data.type = 'ASSET'
        service.search(
            getHostByAppName(BILLING_NAME),
            `invoices/advanced_search/get?size=${size}&page=${page}&sort=reference,DESC`,
            callbackRes,
            (error) => {
                console.log(error);
            },
            data
        );
    }, []);

    const handlerCancelAdvancedSearch = React.useCallback(() => {
        console.log("handlerCancelAdvancedSearch");
        initList();
    }, []);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const actionsComponent = (
        <AdaActions context={context} actionsObj={actionsList} selectedRows={selectedRows}
                    showActions={{exportExcel:false, add:true}} host={`${getHostByAppName(BILLING_NAME)}`}/>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={columnsAssets}
            items={assets}
            setFiltredItems={setFiltered}
        />
    );

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <fieldset>
                                <legend>
                                    <div className="display-3 grey--text">
                                        <IntlMessages id="common.advanced.research"/>

                                        {!advancedSearchShow && (
                                            <i
                                                className="fas fa-search-plus"
                                                style={{fontSize: 25, paddingLeft : 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                        {advancedSearchShow && (<i
                                            className="fas fa-search-minus"
                                            style={{fontSize: 25, paddingLeft : 15}}
                                            onClick={() => setAdvancedSearchShow(!advancedSearchShow)}
                                        />)}

                                    </div>
                                </legend>
                                {advancedSearchShow && (
                                    <BillingAdvancedSearchForm handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}
                                        handlerAdvancedSearch={handlerAdvancedSearch}
                                        isAsset={true}
                                    />
                                )}
                            </fieldset>
                        </div>
                    </div>
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={columnsAssets}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />
                            <Details
                                hideDetails={hideDetailPanel}
                                selectedElement={
                                    selectedRows.length === 1 ? selectedRows[0] : null
                                }
                                context={context}
                            />

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>

                            <Modal
                                show={loadingModal}
                                size="sm"
                                centered
                                backdrop={"static"}
                            >
                                <div className="align-self-center ">
                                    <Row className="pt-3">
                                        <Col>
                                            <Spinner animation="border" color="warning" role="status"
                                                     style={{width: '5rem', height: '5rem'}}/>
                                        </Col>
                                    </Row>
                                    <Row className="pt-3 pb-2"><Col>
                                        <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                            Loading ...
                                        </div>
                                    </Col></Row>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(Invoices);
