import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";
import amountFormat from "../../../commun/amount.format";
import dateFormat from "../../../commun/date.format";

export const columnsInvoices = [

    {
        name: <IntlMessages
            id="sidebar.billing.num"
        />,
        selector: "code",
        width : "10%",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="client.designation"
        />,
        selector: "designation",
        width : "30%",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="invoice.date"
        />,
        selector: "billingDate",
        sortable: false,
        filtrable: true,
        format : row =>dateFormat(row.billingDate)

    },
    {
        name: <IntlMessages
            id="invoice.terms.payment"
        />,
        selector: "paymentDueDate",
        sortable: false,
        filtrable: true,
        format : row =>dateFormat(row.paymentDueDate)
    },
    {
        name: <IntlMessages
            id="invoice.client"
        />,
        selector: "client.name",
        sortable: false,
        filtrable: true,
        format: row => (row.client.entityName ? row.client.name+"/"+row.client.entityName : row.client.name)
    },
    {
        name: <IntlMessages
            id="invoice.total.amount"
        />,
        selector: "totalAmount",
        sortable: false,
        filtrable: true,
        cell : row => <h2>{amountFormat(row.totalAmount)}</h2>
    },
    {
        name: <IntlMessages
            id="invoice.net.amount"
        />,
        selector: "netAmount",
        sortable: false,
        filtrable: true,
        cell : row => <h2>{amountFormat(row.netAmount)}</h2>
    },
    {
        name: <IntlMessages
            id="invoice.net.to.pay"
        />,
        selector: "netToPay",
        sortable: false,
        filtrable: true,
        cell : row => <h2>{amountFormat(row.netToPay)}</h2>
    },
    /*{
        name: <IntlMessages
            id="invoice.total.settled"
        />,
        selector: "netToPay",
        sortable: false,
        filtrable: true,
        cell : row => <h2>{row.totalSettled} €</h2>
    },*/{
        name: "Statut",
        selector: "client.name",
        sortable: false,
        filtrable: true,
        format: row => (row.status !== null && row.status.length > 0 ? getValue(row.status) : "")
    },

];

const getValue =(list)=>{
    let value ="";
    list.forEach(item =>{
        if(item.finished)
            value = item.label
    })
    return value;
}