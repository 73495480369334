import React, {useState} from "react";
import {columnsInvoices} from "./data/columns";
import {
    AdaFilterSubHeader,
    AdaTable,
    AdaActions,
    AdaButton, getConfigObject
} from "@adaming/ada-react-component";
import IntlMessages from "../../../helpers/intlMessages";
import Details from "../details/details";
import {useHistory} from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import BillingAdvancedSearchForm from "../research/advanced.research";
import {Col, Row} from "reactstrap";
import * as service from "../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import filtersAction from "../../../redux/filters/actions";
import {connect} from "react-redux";
import { getColumnsInvoices } from "./data/columns.invoices";
import { GetOrganizationIdFromSession } from "../../../service/session.service";
import axios from "axios";


const {updateFilters} = filtersAction;
const context = "invoices";
const Invoices = props => {
    const [invoices, setInvoices] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    /*invoices detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    let history = useHistory();

    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);
    const [modalErrorShow, setModalErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [billingType, setBillingType] = useState("INVOICE");
    const [dataAdvancedSearch, setDataAdvancedSearch] = useState({transmitter : GetOrganizationIdFromSession().id})


    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.visualize`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`invoice.send.mail`}/>,
                href: "",
                actionHandler: handlerSendByMail,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`invoice.relaunch.mail`}/>,
                href: "",
                actionHandler: handlerRelaunchByMail,
                disabled: true
            },
        ];
    };

    const handlerSendByMail = () => {
        setLoadingModal(true);
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/sendAll`,
            callbackResEnableDisable,
            error => {
                setLoadingModal(false)
            },
            selectedRows.map(obj => obj.id)
        );
    }

    const handlerRelaunchByMail = () => {
        setLoadingModal(true);
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/relaunchAll`,
            callbackResEnableDisable,
            error => {
                setLoadingModal(false)
            },
            selectedRows.map(obj => obj.id)
        );
    }
    const callbackRes = res =>{
        setInvoices(res.data);
        setFiltered(res.data);
        setMaxContent(res.headers['x-content-max'])
    }

    const initList = () => {
        handlerAdvancedSearch({...props.filters.invoiceFilters,type:billingType},null,null)
    }


    /*Create operation List*/
    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Send Action*/
                actionsValue[1] = {...actionsValue[1], disabled: false};
                actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Activate Action*/
                //actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Disable Action*/
                //actionsValue[3] = {...actionsValue[3], disabled: false};
                if (selectedRows.length === 1) {
                    let detailsContext = 'invoices';
                    if(selectedRows[0].billingType === 'ASSET') 
                        detailsContext = 'assets'
                    /*Activate : Edit Action*/
                    actionsValue[0] = {
                        ...actionsValue[0],
                        href: `/${detailsContext}/${selectedRows[0].id}/edit`,
                        disabled: false
                    };
                    /*show detail true*/
                    setHideDetailPanel(false);
                }
                setActionsList(actionsValue);
                setAdvancedSearchShow(false);
                
            }
        },
        [selectedRows.length]
    );

    React.useEffect(()=>{     
        handlerAdvancedSearch({...dataAdvancedSearch, type : billingType},currentPaginationPerRow, currentPage)
    },[currentPaginationPerRow,currentPage])

    const handlerActivateAction = () => {
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), true);
    };

    const handlerDisableAction = () => {
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), false);
    };

    const callbackResEnableDisable = () => {
        setLoadingModal(false);
        setModalSuccessShow(true);
    };

    const handlerEnableDisable = (ids, status) => {
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/enable_disable_invoices`,
            callbackResEnableDisable,
            error => {
            },
            {
                status: status,
                ids: ids
            }
        );
    };

    const handlerAdvancedSearch = React.useCallback((data,size,page) => {
        data.transmitter = GetOrganizationIdFromSession().id;
        setDataAdvancedSearch(data)
        if(size == null){
            size = defaultSizePerPage;
        }
        if(page == null){
            page = defaultPage;
        }
        service.search(
            getHostByAppName(BILLING_NAME),
            `${context}/advanced_search/get?size=${size}&page=${page}&sort=reference,DESC`,
            callbackRes,
            (error) => {
            },
            data
        );
    }, []);

    const handlerCancelAdvancedSearch = React.useCallback(() => {
        initList();
    }, []);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const callbackError = error =>{
        console.log("Error", error);
        setErrorMessage("Erreur lors du téléchargement : "+ error.message);
        setModalErrorShow(true)
    }

    const handleExportExcel = () => {
        var config = getConfigObject();
        config.responseType = 'blob';
        axios.put(getHostByAppName(BILLING_NAME)+'/'+context+'/export/excel/v2', dataAdvancedSearch, config).then(res => {
            const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `export-factures.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch(function (error) {
            callbackError(error);
        });
    }

    const customAdaButtonStyle = {marginRight: 10,'background-color': '#e8a700',border: 'none'};

    const actionsComponent = (
        <div className="flex">
            <AdaButton style={customAdaButtonStyle} onClick={() => props.history.push("invoices/new")} >
                <IntlMessages id="action.add.invoice"/>
            </AdaButton> 
            <AdaButton style={customAdaButtonStyle} onClick={() => props.history.push("assets/new")} >
                <IntlMessages id="action.add.asset"/>
            </AdaButton>
            <AdaButton style={{marginRight: 10, height : 27 , marginTop: 5}} onClick={() =>handleExportExcel()} >
                <IntlMessages id="action.common.export.excel"/>
            </AdaButton>
            <AdaActions context={context} actionsObj={actionsList} selectedRows={selectedRows}
                        showActions={{exportExcel: false, add: false}} host={`${getHostByAppName(BILLING_NAME)}`}/>
        </div>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={columnsInvoices}
            items={invoices}
            setFiltredItems={setFiltered}
        />
    );

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15 full-height">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <fieldset>
                                <legend>
                                    <div className="display-3 grey--text">
                                        <IntlMessages id="common.advanced.research"/>

                                        {!advancedSearchShow && (
                                            <i
                                                className="fas fa-search-plus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                        {advancedSearchShow && (
                                            <i
                                                className="fas fa-search-minus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                    </div>
                                </legend>
                                {advancedSearchShow && (
                                    <BillingAdvancedSearchForm handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}
                                                               handlerAdvancedSearch={handlerAdvancedSearch}
                                                               isAsset={false}
                                                               context={context}
                                                               handlerChangeType={setBillingType}
                                    />
                                )}
                            </fieldset>
                        </div>
                    </div>
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={getColumnsInvoices(setErrorMessage, setLoadingModal, context)}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />
                            <br/>
                            <Details
                                hideDetails={hideDetailPanel}
                                selectedElement={
                                    selectedRows.length === 1 ? selectedRows[0] : null
                                }
                                context={`${context}/get`}
                            />

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>
                            <SweetAlert
                                show={modalErrorShow}
                                onConfirm={() => setModalErrorShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                                error
                            >
                                {errorMessage}
                            </SweetAlert>
                            <Modal
                                show={loadingModal}
                                size="sm"
                                centered
                                backdrop={"static"}
                            >
                                <div className="align-self-center ">
                                    <Row className="pt-3">
                                        <Col>
                                            <Spinner animation="border" color="warning" role="status"
                                                     style={{width: '5rem', height: '5rem'}}/>
                                        </Col>
                                    </Row>
                                    <Row className="pt-3 pb-2"><Col>
                                        <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                            Loading ...
                                        </div>
                                    </Col></Row>
                                </div>
                            </Modal>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        ...state.filters,
        filters: state.filters
    };
};

export default connect(
    mapStateToProps,
    {
        updateFilters
    }
)(Invoices);
