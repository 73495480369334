import React, {useState,useRef} from "react";
import axios from "axios";
import {columnsInvoices} from "../invoice/data/columns";
import {
    AdaFilterSubHeader,
    AdaTable,
    AdaActions,
    AdaButton,
    getConfigObject
} from "@adaming/ada-react-component";
import IntlMessages from "../../../helpers/intlMessages";
import Details from "../details/details";
import {useHistory, withRouter} from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import {Col, Row} from "reactstrap";
import * as service from "../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import BillingAdvancedSearchReportingForm from "../research/advanced.research.reporting";
import amountFormat from "../../commun/amount.format";
import {columnsAssets} from "../asset/data/columns";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { GetOrganizationIdFromSession } from "../../../service/session.service";

const context = "invoices";
const Reporting = props => {
    const [invoices, setInvoices] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    /*invoices detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);

    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    const [modalLoadingShow, setModalLoadingShow] = useState(false);
    const [modalErrorShow, setModalErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const[isInvoice, setIsInvoice]=useState(true);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);

    const [amountObj, setAmountObj] = useState({totalHT:0, netToPayHT:0, total:0, netToPay:0})
    let history = useHistory();

    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);
    const [filters, setFilters] = useState({transmitter: GetOrganizationIdFromSession().id})
    const isFirstRender = useRef(true);

    const handlerSendByMail = () => {
        setLoadingModal(true);
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/sendAll`,
            callbackResEnableDisable,
            error => {
                setLoadingModal(false)
            },
            selectedRows.map(obj => obj.id)
        );
    }

    const handlerRelaunchByMail = () => {
        setLoadingModal(true);
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/relaunchAll`,
            callbackResEnableDisable,
            error => {
                setLoadingModal(false)
            },
            selectedRows.map(obj => obj.id)
        );
    }
    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.visualize`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`invoice.send.mail`}/>,
                href: "",
                actionHandler: handlerSendByMail,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`invoice.relaunch.mail`}/>,
                href: "",
                actionHandler: handlerRelaunchByMail,
                disabled: true
            },
        ];
    };

    const callbackResInvoices = res => {
        setMaxContent(parseInt(res.headers['x-content-max'],10));
        setInvoices(res.data);
        setFiltered(res.data);
        setModalLoadingShow(false)
    };

    const callbackRes = res => {
        setAmountObj({totalHT: res.data.totalHT, netToPayHT: res.data.netToPayHT, total: res.data.total, netToPay: res.data.netToPay})
    };

    /*Initialization Invoices List */
    React.useEffect(
        () => {
            if (!modalSuccessShow) {
                //initList();
            }
        },
        [modalSuccessShow]
    );

    React.useEffect(()=>{
        handlerAdvancedSearch(filters,null,null)
    },[])

    React.useEffect(()=>{     
        if(!isFirstRender.current){
            handlerAdvancedSearchInvoices(filters,currentPaginationPerRow, currentPage)
        }else{
            isFirstRender.current = false;
        }
    },[currentPaginationPerRow,currentPage])

    /*Create operation List*/
    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Send Action*/
                actionsValue[1] = {...actionsValue[1], disabled: false};
                actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Activate Action*/
                //actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Disable Action*/
                //actionsValue[3] = {...actionsValue[3], disabled: false};
                if (selectedRows.length === 1) {
                    let detailsContext = 'invoices';
                    if(selectedRows[0].billingType === 'ASSET')
                        detailsContext = 'assets'
                    /*Activate : Edit Action*/
                    actionsValue[0] = {
                        ...actionsValue[0],
                        href: `/${detailsContext}/${selectedRows[0].id}/edit`,
                        disabled: false
                    };
                    /*show detail true*/
                    setHideDetailPanel(false);
                }
                setActionsList(actionsValue);

            }
        },
        [selectedRows.length]
    );

    const handlerActivateAction = () => {
        console.log("in Activate action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), true);
    };

    const handlerDisableAction = () => {
        console.log("in Disable action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), false);
    };

    const callbackResEnableDisable = () => {
        setLoadingModal(false);
        setModalSuccessShow(true);
    };

    const handlerEnableDisable = (ids, status) => {
        console.log("in handlerEnableDisable action", ids, status);
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/enable_disable_invoices`,
            callbackResEnableDisable,
            error => {
                console.log(error);
            },
            {
                status: status,
                ids: ids
            }
        );
    };

    const handlerAdvancedSearchInvoices = React.useCallback((data,size,page) => {
        if(size == null){
            size = defaultSizePerPage;
        }
        if(page == null){
            page = defaultPage;
        }
        setModalLoadingShow(true)
        service.search(
            getHostByAppName(BILLING_NAME),
            `invoices/advanced_search/get?size=${size}&page=${page}`,
            callbackResInvoices,
            (error) => {
                setModalLoadingShow(false)
                console.log(error);
            },
            data
        );
    }, []);

    const handlerAdvancedSearch = React.useCallback((data,size,page) => {
        handlerAdvancedSearchInvoices(data,size,page)
        service.search(
            getHostByAppName(BILLING_NAME),
            `reporting/advanced_search/get`,
            callbackRes,
            (error) => {
                setModalLoadingShow(false)
                console.log(error);
            },
            data
        );
    }, []);

    const handlerCancelAdvancedSearch = React.useCallback(() => {
        console.log("handlerCancelAdvancedSearch");
        setFiltered([])
        setAmountObj({totalHT:0, netToPayHT:0, total:0, netToPay:0})
    }, []);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const callbackError = error =>{
        console.log("Error", error);
        setErrorMessage("Erreur lors du téléchargement : "+ error.message);
        setModalErrorShow(true)
    }
    const handleExportExcel = () => {
        var config = getConfigObject();
        config.responseType = 'blob';
        axios.put(getHostByAppName(BILLING_NAME)+'/'+context+'/export/excel/v2', filters, config).then(res => {
            const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `export-factures.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);

        }).catch(function (error) {
            callbackError(error);
        });
    }

    const actionsComponent = (
        <div className="flex">
        <AdaButton style={{marginRight: 10, height : 27 , marginTop: 5}} onClick={() =>handleExportExcel()} >
        <IntlMessages id="action.common.export.excel"/>
    </AdaButton>
    <AdaActions context={context} selectedRows={selectedRows} actionsObj={actionsList}
                    showActions={{exportExcel:false, add:false}} host={`${getHostByAppName(BILLING_NAME)}`}/>
        </div>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={isInvoice ? columnsInvoices : columnsAssets}
            items={invoices}
            setFiltredItems={setFiltered}
        />
    );

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15 full-height">
                    <BillingAdvancedSearchReportingForm handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}
                                                        handlerAdvancedSearch={filters => {
                                                            setFilters(filters);
                                                            handlerAdvancedSearch(filters,currentPaginationPerRow, currentPage)}}
                                                        isAsset={false}
                                                        context={context} amountObj={amountObj}
                    />

                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={isInvoice ? columnsInvoices.concat({name: "Type", selector: "billingType",sortable: false, filtrable: true}) : columnsAssets.concat({name: "Type", selector: "billingType",sortable: false, filtrable: true})}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />

                            <br/>
                            <Details
                                hideDetails={hideDetailPanel}
                                selectedElement={
                                    selectedRows.length === 1 ? selectedRows[0] : null
                                }
                                context={`${isInvoice ? "invoices/get" : "assets"}`}
                            />

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>
                            <SweetAlert
                                show={modalErrorShow}
                                onConfirm={() => setModalErrorShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                                error
                            >
                                {errorMessage}
                            </SweetAlert>
                            <Modal
                                show={modalLoadingShow}
                                size="sm"
                                centered
                                backdrop={"static"}
                            >
                                <div className="align-self-center ">
                                    <Row className="pt-3">
                                        <Col>
                                            <Spinner animation="border" color="warning" role="status"
                                                     style={{width: '5rem', height: '5rem'}}/>
                                        </Col>
                                    </Row>
                                    <Row className="pt-3 pb-2"><Col>
                                        <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                            Loading ...
                                        </div>
                                    </Col></Row>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(Reporting);
