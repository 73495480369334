import React, {useState} from "react";
import {Col, FormGroup, Row} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router";
import {useForm} from "react-hook-form";
import InvoiceClient from "../../components/invoice.client";
import InvoiceTransmitter from "../../components/invoice.transmitter";
import InvoiceObject from "../../components/invoice.object";
import InvoiceLines from "../../components/invoice.lines";
import InvoiceNote from "../../components/invoice.note";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import InvoiceAction from "../../components/invoice.actions";
import Steps from "../../components/steps";
import CustomInput from "reactstrap/es/CustomInput";
import Toast from "react-bootstrap/Toast";
import ToastBody from "react-bootstrap/ToastBody";
import AssetDate from "../../components/asset.date";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const context = "assets";
export default function AssetsForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();
    const [isValid, setIsValid] = useState(false);
    const [asset, setAsset] = useState({});
    const [edit, setEdit] = useState(true);
    const [listStep, setListStep] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingModal, setLoadingModal] = React.useState(false);

    let history = useHistory();


    const cancelCreateInvoice = () => {
        history.push("/assets");
    };

    const onSubmitInvoice = data => {
        if (isValid) {
            if (data.netAmount === 0 || data.netAmount === undefined) {
                setErrorMessage(
                    "Montant invalide, montant doit être supérieur strictement à 0 € "
                );
                return;
            }
            if(!data.client.id){
                setErrorMessage(
                    "Merci de mentionner le client"
                );
                return;
            }
            setLoadingModal(true)
            console.log("Save asset : ", mapData(data));
            console.log(JSON.stringify(mapData(data)));
            service.post(
                getHostByAppName(BILLING_NAME),
                `${context}/create`,
                callbackRes,
                callbackError,
                mapData(data)
            );
        } else {
            console.log("is not valid");
        }
    };

    const callbackRes = res => {
        console.log(res.data);
        setLoadingModal(false)
        history.push(`/${context}/${res.data.id}/edit`);
    };

    const callbackError = error => {
        console.log("error to print", error.message);
        setLoadingModal(false)
        setErrorMessage(error.response.data.message)
    };

    const mapData = data => {
        console.log("asset data", data);
        return {
            ...data,
            status: [],
            client: {
                client: data.client.id,
                entity: data.client.entity ? data.client.entity.id : null,
                name: data.client.name,
                entityName: data.client.entity ? data.client.entity.name : null,
                bankDetails: data.client.bankDetails,
                contact: data.client.contact,
                addressBilling: data.client.addressBilling,
                administrationInformation: data.client.administrationInformation,
                contactInformation: data.client.contactInformation
            },
            transmitter: {
                organization: data.transmitter.id,
                name: data.transmitter.name,
                address:
                    data.transmitter.address !== undefined
                        ? data.transmitter.address
                        : null,
                bankDetails: data.transmitter.bankDetails,
                contactInformation: data.transmitter.contactInformation,
                administrationInformation: data.transmitter.administrationInformation
            }
        }
    }

    return (
        <div>
            <div>
                <Row>
                    <Col className="col-sm-3">
                        <div>
                            <h4 className="mb-0 fs-18 bold-text" style={{color:"#FF0B0E"}}>Ajouter un avoir</h4>
                        </div>
                    </Col>
                    <Col className="col-sm-6">
                        <Row>
                            <Col>
                                {!edit && <Steps steps={listStep}/>}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="pl-5 col-sm-3">
                        {!edit && <InvoiceAction/>}
                    </Col>
                </Row>

                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body col-sm-12">


                                <br/>
                                <form onSubmit={handleSubmit(onSubmitInvoice)}>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <div className="display-5 c-text-danger">
                                                {errorMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <AssetDate
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                edit={true}
                                            />

                                            <InvoiceTransmitter
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                invoice={asset}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                        <Col className="col-sm-6">
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceClient
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={asset}
                                                edit={edit} setClientTermsOfPayment={(msg)=> console.log(msg)}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceObject
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={asset}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                            <InvoiceLines
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={asset}
                                                edit={edit} showTotalSettled={false}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <br/>
                                    <Row>
                                        <Col>
                                            <hr/>
                                            <Row className="mr-n3">

                                                <Col sm={{size: 6, order: 1, offset: 0}}>
                                                    <PageviewsChartWrapper>
                                                        <div className="page-view-chart-widget-card pl-4 pt-3 pb-4 pr-5">
                                                    <InvoiceNote
                                                        errors={errors}
                                                        register={register}
                                                        setValue={setValue}
                                                        reset={reset}
                                                        invoice={asset}
                                                        edit={edit}
                                                    />
                                                        </div>
                                                    </PageviewsChartWrapper>
                                                </Col>

                                                <Col sm={{size: 3, order: 2, offset: 0}}>
                                                    <Toast>
                                                        <ToastBody>
                                                            <CustomInput
                                                                type="switch"
                                                                id="penaltyRate"
                                                                name="penaltyRate"
                                                                defaultChecked={false}
                                                                innerRef={register}
                                                                label={<IntlMessages id="asset.penaltyRate"/>}
                                                            />
                                                            <IntlMessages id="asset.penaltyRate.text"/>
                                                        </ToastBody>
                                                    </Toast>
                                                </Col>
                                                <Col sm={{size: 3, order: 3, offset: 0}}>
                                                    <Toast>
                                                        <ToastBody>
                                                            <CustomInput
                                                                type="switch"
                                                                id="collectionCosts"
                                                                name="collectionCosts"
                                                                defaultChecked={false}
                                                                innerRef={register}
                                                                label={<IntlMessages id="asset.collectionCosts"/>}
                                                            />
                                                            <IntlMessages id="asset.collectionCosts.text"/>
                                                        </ToastBody>
                                                    </Toast>
                                                </Col>

                                            </Row>

                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col className="col-sm-8"/>
                                        <Col>
                                            {edit && <FormGroup>
                                                <AdaButton className="c-btn c-danger w-100" onClick={cancelCreateInvoice}>
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>

                                            </FormGroup>}
                                        </Col>
                                        <Col>
                                            {edit && <FormGroup>
                                                <AdaButton
                                                    type="submit" className="c-btn c-warning w-100"
                                                    onClick={() => setIsValid(true)}
                                                    style={{marginLeft: 10}}
                                                >
                                                    <IntlMessages id="action.common.submit"/>
                                                </AdaButton>
                                            </FormGroup>}
                                        </Col>
                                    </Row>

                                    <Modal
                                        show={loadingModal}
                                        size="sm"
                                        centered
                                        backdrop={"static"}
                                    >
                                        <div className="align-self-center ">
                                            <Row className="pt-3">
                                                <Col>
                                                    <Spinner animation="border" color="warning" role="status"
                                                             style={{width: '5rem', height: '5rem'}}/>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3 pb-2"><Col>
                                                <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                                    Chargement ...
                                                </div>
                                            </Col></Row>
                                        </div>
                                    </Modal>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
