import React, {useEffect, useState} from "react";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    Col,
    NavLink,
    Row, FormGroup
} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../../helpers/intlMessages";
import {useForm} from "react-hook-form";
import AssignmentDisplayCollab from "../assignment/assignment.consultation/assignment.display.collab";
import AssignmentContractTypeAndDateOrder
    from "../assignment/assignment.consultation/assignment.display.contractTypeOrder";
import {AdaButton, AdaInputText} from "@adaming/ada-react-component";
import AssignmentDisplayClient from "../assignment/assignment.consultation/assignment.display.client";
import AssignmentSellPriceTable from "../assignment/assignment.consultation/assignment.sellPriceTable";
import AssignmentDiscountPriceTable from "../assignment/assignment.consultation/assignment.discountPriceTable";
import * as service from "../../../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName} from "../../../../service/host";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";
import Documents from "../../components/invoice.documents";



export default function AssignmentDetails({hideDetails, selectedElement}) {
    const [activeTab, setActiveTab] = useState("1");
    const [assignment, setAssignment] = React.useState({});
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(
        () => {
            console.log("Hide details :", hideDetails);
            if (!hideDetails) {
                setActiveTab("1")
                if (selectedElement !== null && selectedElement !== undefined) {
                    console.log("Id for details :", selectedElement);
                    service.getById(
                        getHostByAppName(ASSIGNMENT_NAME),
                        `assignments`,
                        setAssignment,
                        ((error) => {console.log(error)}),
                        selectedElement
                    );
                }
            }
        },
        [hideDetails]
    );


    return (
        !hideDetails && (
            <div>
                <Nav tabs>
                    <NavItem className="roe-card-body">
                        <NavLink
                            className={classnames({
                                active: activeTab === "1"
                            })}
                            onClick={() => {
                                toggle("1");
                            }}
                        >
                            <IntlMessages id={`action.employee`}/>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === "2"
                            })}
                            onClick={() => {
                                toggle("2");
                            }}
                        >
                            <IntlMessages id={`assignment.client.direct`}/> / <IntlMessages id={`assignment.client.undirect`}/>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === "4"
                            })}
                            onClick={() => {
                                toggle("4");
                            }}
                        >
                            <IntlMessages id={`assignment.price.and.sell`}/>
                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Col sm="12">
                            <Row className="ptb-10">
                                <Col className="col-sm-6">
                                    <AssignmentDisplayCollab assignment={assignment}/>
                                </Col>

                                <Col className="col-sm-6">
                                    <PageviewsChartWrapper>
                                        <div className="page-view-chart-widget-card pl-4 pt-3 pb-3">
                                            <Row className="pt-3">
                                                <Col>
                                                    <div>
                                                        <span className="hash"># </span>
                                                        <IntlMessages id="employee.mission.Type"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3 ">
                                                <Col >
                                                    <AssignmentContractTypeAndDateOrder
                                                        assignment={assignment}
                                                        register={register}
                                                        edit={false}
                                                        errors={errors}
                                                        setValue={setValue}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="pt-3">
                                                <Col>
                                                    <div>
                                                        <span className="hash"># </span>
                                                        <IntlMessages id="common.mission.description"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3">
                                                <Col className="col-sm-11">
                                                    <div className="pl-3">
                                                        <AdaInputText
                                                            name="designation"
                                                            errors={errors}
                                                            innerRef={register}
                                                            defaultValue={
                                                                assignment !== undefined
                                                                    ? assignment.designation
                                                                    : ""
                                                            }
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3">
                                                <Col>
                                                    <div>
                                                        <span className="hash"># </span>
                                                        <IntlMessages id="assignment.mission.task"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3 mr-3">
                                                <Col>
                                                    <div className="pl-3">
                                                        <AdaInputText
                                                            name="task"
                                                            errors={errors}
                                                            innerRef={register}
                                                            defaultValue={
                                                                assignment !== undefined
                                                                    ? assignment.task
                                                                    : ""
                                                            }
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </PageviewsChartWrapper>
                                </Col>
                            </Row>
                        </Col>
                    </TabPane>
                    <TabPane tabId="2">
                        <Col sm="14">
                            {assignment.directClient !== undefined &&
                            assignment.directClient !== null &&
                            (activeTab === "2" && (
                                <AssignmentDisplayClient
                                    clientObject={assignment.directClient}
                                    type={<IntlMessages id="assignment.client.direct"/>}
                                />))}
                            {assignment.subContractingClient !== undefined &&
                            assignment.subContractingClient !== null &&
                            (activeTab === "2" && (
                                <AssignmentDisplayClient
                                    clientObject={assignment.subContractingClient}
                                    type={<IntlMessages id="assignment.subContractingClient"/>}
                                />
                            ))}
                        </Col>
                    </TabPane>

                    <TabPane tabId="4">
                        <Col sm="14">
                            <br/>
                            {activeTab === "4" && (
                                <PageviewsChartWrapper>
                                    <div className="page-view-chart-widget-card pl-4 pt-3 pb-3">
                                        <Row className="ptb-10">

                                            <Col className="col-sm-6">
                                                <AssignmentSellPriceTable
                                                    assignment={assignment}
                                                    setValue={setValue}
                                                    errors={errors}
                                                    details={true}
                                                    edit={false}
                                                    register={register}
                                                />
                                            </Col>
                                            <Col className="col-sm-6">
                                                <AssignmentDiscountPriceTable
                                                    assignment={assignment}
                                                    setValue={setValue}
                                                    errors={errors}
                                                    details={true}
                                                    edit={false}
                                                    register={register}
                                                />
                                            </Col>

                                        </Row>
                                    </div>
                                </PageviewsChartWrapper>
                            )}
                        </Col>
                    </TabPane>
                </TabContent>
            </div>
        )
    );
}
