import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaSelect} from "@adaming/ada-react-component";
import * as service from "../../../service/crud.service";
import * as personService from "../../../service/employee.service";
import {paymentMethod} from "../../commun/enum/paymentMethod";
import {CRM_NAME, getHostByAppName} from "../../../service/host";

export default function InvoiceClient({register, setValue, errors, reset, invoice, edit, setClientTermsOfPayment}) {
    const [client, setClient] = useState({});
    const selectedEntity = useRef()
    const [clientList, setClientList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [paymentTypeList, setPaymentTypeList] = useState(paymentMethod);

    useEffect(() => {
        register({name: "client"});
        register({name: "client.entity"});
        register({name: "client.addressBilling"});
        register({name: "client.contact"});
        register({name: "paymentType"});
    }, []);

    const showAddress = addressBilling => {
        if (addressBilling !== undefined) {
            return (
                addressBilling.number +
                " " +
                addressBilling.street +
                " " +
                addressBilling.city +
                " " +
                addressBilling.zipCode
            );
        }
    };

    useEffect(() => {
        /*List Affiliation Client rest service */
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
    }, [])

    useEffect(() => {

    }, [])

    const handleChangeClient = selectedOption => {
        console.log("client", selectedOption);
        if (selectedOption !== null) {
            setValue("client", selectedOption.object);
            setValue("client.entity", null)
            setValue("client.contact", null)
            setClient(selectedOption.object);
            setClientTermsOfPayment(selectedOption.object.termsOfPayment);
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${selectedOption.object.id}`, setEntityList)
            personService.getAllPersonSelect(getHostByAppName(CRM_NAME), `contacts/related/${selectedOption.object.id}`, setContactList)
            service.get(getHostByAppName(CRM_NAME), `clients/${selectedOption.object.id}/address`, setAddressToClient, ((error)=>console.log(error)));
        } else {
            setEntityList([]);
            setContactList([]);
            setValue("client", undefined);
            setValue("client.entity", null)
            setValue("client.addressBilling", null)
            setValue("client.contact", undefined)
            setClientTermsOfPayment("");
        }
    };

    const setAddressToClient = (address) =>{
        console.log("address Client :",address)
        let addressObj = address !== undefined && address !== null && address.length>0 ? address[0] : null;
        console.log("addressObj Client :",addressObj)
        setValue("client.addressBilling", addressObj)
    }

    const handleChangeEntity = selectedOption => {
        console.log("entity", selectedOption);
        if (selectedOption !== null) {
            setValue("client.entity", selectedOption.object);
            setContactList([]);
            personService.getAllPersonSelect(getHostByAppName(CRM_NAME), `contacts/related/${selectedOption.object.id}`, setContactList)
        } else {
            personService.getAllPersonSelect(getHostByAppName(CRM_NAME), `contacts/related/${client.id}`, setContactList)
            setValue("client.entity", undefined)
            setValue("client.contact", undefined);
        }
    };


    const handleChangeContact = selectedOption => {
        console.log("contact", selectedOption);
        if (selectedOption !== null) {
            setValue("client.contact", selectedOption.object);
        } else {
            setValue("client.contact", undefined);
        }
    };

    const handleChangePaymentType = selectedOption => {
        console.log("paymentType", selectedOption);
        if (selectedOption !== null) {
            setValue("paymentType", selectedOption.value);
        } else {
            setValue("paymentType", undefined);
        }
    };

    return (
        <div className="pt-2">
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="invoice.client"/>
                        <AdaSelect
                            isClearable
                            name="client"
                            errors={errors}
                            options={clientList}
                            onChange={handleChangeClient}
                            innerRef={register}
                            isDisabled={!edit}
                        />

                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Row>
                            <Col className="col-sm-6">
                                <IntlMessages id="invoice.entity"/>
                                <AdaSelect
                                    isClearable
                                    name="client.entity"
                                    errors={errors}
                                    options={entityList}
                                    onChange={handleChangeEntity}
                                    innerRef={register}
                                    isDisabled={!edit}
                                />
                            </Col>
                            <Col className="col-sm-6">
                                <IntlMessages id="invoice.contact"/>
                                <AdaSelect
                                    isClearable
                                    name="client.contact"
                                    errors={errors}
                                    options={contactList}
                                    onChange={handleChangeContact}
                                    innerRef={register}
                                    isDisabled={!edit}
                                />

                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <FormGroup>
                        <IntlMessages id="invoice.payment.type"/>
                        <AdaSelect
                            isClearable
                            name="paymentType"
                            errors={errors}
                            options={paymentTypeList}
                            onChange={handleChangePaymentType}
                            innerRef={register}
                            isDisabled={!edit}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}
