import React, {useEffect, useState} from "react";
import {FormGroup, Row, Table} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";

export default function InvoiceEvents({invoice}) {


    return (
        <div>
            <Row>
                <Col>
                    <Row className="pt-3 pb-3">
                        <Col>
                            <div>
                                <span className="hash"># </span>
                                <IntlMessages id="common.event.history"/>
                            </div>
                        </Col>
                    </Row>
                    <Table hover responsive bordered striped>
                        <thead align="left">

                        <tr>
                            <th width="20%">
                                <div className="display-5 c-text-secondary"> <IntlMessages id="common.date"/></div>
                            </th>
                            <th width="40%">
                                <div className="display-5 c-text-secondary"> <IntlMessages id="common.event"/></div>
                            </th>

                            <th width="20%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="common.user"/></div>
                            </th>

                        </tr>
                        </thead>

                        <tbody>
                        {invoice.eventsBilling !== undefined && invoice.eventsBilling !== null ? (invoice.eventsBilling.map((line, key) => (
                            <tr key={key}>

                                <td>{line.date}</td>
                                <td>
                                    {line.event}
                                </td>
                                <td>{line.user}</td>

                            </tr>
                        ))) : "pas de donnée"}
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </div>
    );
}
