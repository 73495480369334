import React, {useState} from "react";

import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";


export default function SendAction({
                                       setInvoice,
                                       setModalSuccessShow,
                                       invoice, setErrorMessage, context, setModalLoadingShow
                                   }) {
    const [send, setSend] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const callbackRes = res => {
        console.log(res.data);
        setInvoice(res.data);
        setModalLoadingShow(false)
        setModalSuccessShow(true);
    };

    const callbackError = error => {
        setErrorMessage(error.message);
        setModalLoadingShow(false)
        console.log("error to print", error.message);
    };

    const onIsSent = () => {
        console.log("in is sent invoice ",);
        setModalLoadingShow(true)
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/issent/${invoice.id}/`,
            callbackRes,
            callbackError
        );
    };

    const sendAutomatically = () => {
        console.log("in is sent invoice ",);
        setModalLoadingShow(true)
        service.put(
            getHostByAppName(BILLING_NAME),
            `${context}/send/automatically/${invoice.id}/`,
            callbackRes,
            callbackError
        );
    };

    return (
        <div>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle style={{height: 32, width:120}}  className="c-btn ma-5 no-border c-outline-primary">
                    <i className="far fa-envelope" style={{fontSize: 12}}> Envoyer</i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => sendAutomatically()}>Par mail</DropdownItem>
                    <DropdownItem onClick={() => onIsSent()}>Marquer comme Envoyé</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}
