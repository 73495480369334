import React, {useEffect, useState} from "react";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import IntlMessages from "../../../helpers/intlMessages";
import {Col, FormGroup, Row} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import {month} from "../../commun/enum/month";
import * as service from "../../../service/crud.service";
import {BILLING_NAME, CRM_NAME, EMPLOYEE_NAME, getHostByAppName, ORGANIZATION_NAME} from "../../../service/host";
import * as specificService from "../../../service/employee.service";
import {GetOrganizationIdFromSession} from "../../../service/session.service";
import {connect} from "react-redux";

import filtersAction from "../../../redux/filters/actions";
const {updateFiltersPayment} = filtersAction;

const BillingAdvancedSearchPaymentForm = (props) =>{

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [organizationList, setOrganizationList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [clientGroupList, setClientGroupList] = useState([]);

    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState(month);
    const [employeeList, setEmployeeList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [contactList, setContactList] = useState([]);

    const handlerAdvancedSearch = props.handlerAdvancedSearch;
    const isAsset = props.isAsset;
    const handlerCancelAdvancedSearch = props.handlerCancelAdvancedSearch;



    useEffect(() => {
        /*List Organization Client rest service */
        service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, setOrganizationList)
        service.getAllObj(getHostByAppName(CRM_NAME), `clients/groups`, setClientGroupList)
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
        service.getAllObj(getHostByAppName(BILLING_NAME), `billings/getYear`, setYearList)
        specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
    }, []);

    useEffect(() => {
        setValue("client", getSelectedClient(clientList));
    }, [clientList]);
    useEffect(() => {
        setValue("clientGroup", getSelectedClientGroup(clientGroupList));
    }, [clientGroupList]);
    useEffect(() => {
        setValue("year", getSelectedYear(yearList));
    }, [yearList]);

    const onSubmitInvoiceSearch = (data) => {
        handlerAdvancedSearch({...data, ...props.filters.paymentFilters});
    };

    const clear =() =>{
        reset({
            client: null,
            clientGroup: null,
            entity: null,
            month: null,
            year: null,
            paymentDueDate: null,
            billingPeriodStart: null,
            billingPeriodEnd: null,
            transmitter: GetOrganizationIdFromSession().id,
            employee: null,
            reference: null
        });
        props.updateFiltersPayment({
            client: null,
            clientGroup: null,
            entity: null,
            month: null,
            year: null,
            paymentDueDate: null,
            billingPeriodStart: null,
            billingPeriodEnd: null,
            transmitter: GetOrganizationIdFromSession().id,
            employee: null,
            reference: null

        })
        handlerCancelAdvancedSearch();
    }

    const handleChangeOrganization = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("transmitter", selectedOption.key);
            props.updateFiltersPayment({...props.filters.paymentFilters, transmitter: selectedOption.key})
        } else {
            setValue("transmitter", null);
            props.updateFiltersPayment({...props.filters.paymentFilters, transmitter: null})
        }
    };

    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("year", selectedOption.value);
            props.updateFiltersPayment({...props.filters.paymentFilters, year: selectedOption.value})
        } else {
            setValue("year", null);
            props.updateFiltersPayment({...props.filters.paymentFilters, year: null})
        }
    };

    const handleChangeMonth = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("month", selectedOption.value);
            props.updateFiltersPayment({...props.filters.paymentFilters, month: selectedOption.value})
        } else {
            setValue("month", null);
            props.updateFiltersPayment({...props.filters.paymentFilters, month: null})
        }
    };




    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
            props.updateFiltersPayment({...props.filters.paymentFilters, employee: selectedOption.key})
        } else {
            setValue("employee", null);
            props.updateFiltersPayment({...props.filters.paymentFilters, employee: null})
        }
    };


    const handleChangeClientGroup = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("clientGroup", selectedOption.key);
            setValue("client", null);
            console.log("clientGroup changed ",selectedOption.key )
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients/findByGroup/${selectedOption.key}`, setClientList)

            props.updateFiltersPayment({...props.filters.paymentFilters, clientGroup: selectedOption.key})
        } else {
            setValue("clientGroup", null);
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
            props.updateFiltersPayment({...props.filters.paymentFilters, clientGroup: null})
        }
    };


    const handleChangeClient = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("client", selectedOption.key);
            setValue("entity", null);
            props.updateFiltersPayment({...props.filters.paymentFilters, client: selectedOption.key, entity: null})
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${selectedOption.key}`, setEntityList)
        } else {
            setValue("client", null);
            setValue("entity", null);
            setEntityList([])
            props.updateFiltersPayment({...props.filters.paymentFilters, client: null, entity: null})
        }
    };

    const handleChangeEntity = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("entity", selectedOption.key);
            props.updateFiltersPayment({...props.filters.paymentFilters, entity: selectedOption.key})
        } else {
            setValue("entity", null);
            props.updateFiltersPayment({...props.filters.paymentFilters, entity: null})
        }
    };


    const handleChangeContact = selectedOption => {
        if (selectedOption !== null) {
            setValue("billingContact", selectedOption.label);
        } else {
            setValue("billingContact", undefined);
        }
    };

    const getSelectedEntity = (entityList) =>{
        const filtered =  entityList.find(entity => entity.object.id === props.filters.paymentFilters.entity);
        if(filtered){
          return filtered
        }else return null;
      }

      const getSelectedClient = (clientList) =>{
        const filtered =  clientList.find(client => client.object.id === props.filters.paymentFilters.client);
        if(filtered){
          return filtered
        }else return null;
      }
      const getSelectedClientGroup = (clientGroupList) =>{
        const filtered =  clientGroupList.find(group => group.id === props.filters.paymentFilters.clientGroup);
        if(filtered){
          return filtered
        }else return null;
      }

      const getSelectedYear = (yearList) =>{
        const filtered =  yearList.find(y => y.value === props.filters.paymentFilters.year);
        if(filtered){
          return filtered
        }else return null;
      }



    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitInvoiceSearch)}>
                <Row className="col-sm-12 pt-3">
                    <Col className="col-sm-4">
                        <Row>
                            <Col>
                                <FormGroup>
                                    {" "}
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.group.client"/>
                                    </div>
                                    <Controller
                                        as={<AdaSelect name={"clientGroup"}/>}
                                        name={"clientGroup"}
                                        isClearable
                                        errors={errors}
                                        options={clientGroupList}
                                        onChange={handleChangeClientGroup}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    {" "}
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.client"/>
                                    </div>
                                    <Controller
                                        as={<AdaSelect name={"client"}/>}
                                        name={"client"}
                                        isClearable
                                        errors={errors}
                                        options={clientList}
                                        onChange={handleChangeClient}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        

                        <Row>
                            <Col>
                                <FormGroup>
                                    {" "}
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.entity"/>
                                    </div>
                                    <Controller
                                        as={<AdaSelect name={"entity"}/>}
                                        name={"entity"}
                                        isClearable
                                        errors={errors}
                                        options={entityList}
                                        onChange={handleChangeEntity}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {/*<Row>
                        <Col>
                            <FormGroup>
                                {" "}
                                <div className="grey--text">
                                    <IntlMessages id="invoice.contact"/>
                                </div>
                                <AdaSelect
                                    isClearable
                                    name="billingContact"
                                    errors={errors}
                                    options={contactList}
                                    onChange={handleChangeContact}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>*/}
                    </Col>


                    <Col className="col-sm-4 pl-5">

                        <Row>
                            <Col className="col-sm-11">
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <div className="grey--text">
                                                <IntlMessages id="invoice.from.period"/>
                                            </div>
                                            <AdaInputText
                                                type="date" style={{height:38}}
                                                name="billingPeriodStart"
                                                errors={errors}
                                                innerRef={register}
                                                onBlur={e => props.updateFiltersPayment({...props.filters.paymentFilters, billingPeriodStart: e.target.value})}
                                                defaultValue={props.filters.paymentFilters.billingPeriodStart}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <FormGroup>
                                            <div className="grey--text">
                                                <IntlMessages id="invoice.end.period"/>
                                            </div>
                                            <AdaInputText
                                                type="date" style={{height:38}}
                                                name="billingPeriodEnd"
                                                errors={errors}
                                                innerRef={register}
                                                onBlur={e => props.updateFiltersPayment({...props.filters.paymentFilters, billingPeriodEnd: e.target.value})}
                                                defaultValue={props.filters.paymentFilters.billingPeriodEnd}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>


                    <Col className="col-sm-3  pl-5">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.reference"/>
                                    </div>
                                    <AdaInputText
                                        type="text" style={{height:38}}
                                        name="reference"
                                        errors={errors}
                                        innerRef={register}
                                        onBlur={e => props.updateFiltersPayment({...props.filters.paymentFilters, reference: e.target.value})}

                                        defaultValue={props.filters.paymentFilters.reference}
                                        
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-4 pl-5">
                                <FormGroup>
                                    <AdaButton className="c-danger col-xl-5" onClick={()=>clear()}>
                                        <IntlMessages id="action.common.cancel"/>
                                    </AdaButton>
                                    <AdaButton
                                        className="c-warning col-xl-5"
                                        type="submit"
                                        style={{marginLeft: 10}}
                                    >
                                        <IntlMessages id="common.search"/>
                                    </AdaButton>
                                </FormGroup>
                            </Col>
                        </Row>


                    </Col>

                </Row>


            </form>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        ...state.filters,
        filters: state.filters
    };
};

export default connect(
    mapStateToProps,
    {
        updateFiltersPayment
    }
)(BillingAdvancedSearchPaymentForm);
