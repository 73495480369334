import React, {useEffect, useState} from "react";
import {Col, Row, FormGroup} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router";
import InvoiceDate from "../../components/invoice.date";
import {useForm} from "react-hook-form";
import InvoiceObject from "../../components/invoice.object";
import InvoiceLines from "../../components/invoice.lines";
import InvoiceNote from "../../components/invoice.note";
import {AdaButton} from "@adaming/ada-react-component";
import InvoiceDisplayTransmitter from "../../components/invoice.display.transmitter";
import InvoiceDisplayClient from "../../components/invoice.display.client";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../../service/host";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const context = "invoices";

export default function InvoiceDuplicateForm(props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();
    const [isValid, setIsValid] = useState(false);
    const [invoice, setInvoice] = useState({});
    const [edit, setEdit] = useState(false);

    const [listStep, setListStep] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [loadingModal, setLoadingModal] = React.useState(false);


    let history = useHistory();
    const invoiceId = initInvoiceId();

    function initInvoiceId() {
        const {
            match: {params}
        } = props;
        return params.invoiceId;
    }

    const callbackResGet = res => {
        console.log("get invoice by ID : ", res);
        setInvoice({
            ...res,
            netToPay: res.netAmount,
            totalSettled: 0.0,
            designation:
                "Création suite à l'annulation totale de la facture :" +
                res.id
        });
    };

    const callbackError = error => {
        setErrorMessage(error.response.data.message)
        setLoadingModal(false)
        console.log(error);
    };

    React.useEffect(() => {
        if (invoiceId !== undefined) {
            service.get(
                getHostByAppName(BILLING_NAME),
                `${context}/get/${invoiceId}`,
                callbackResGet,
                error => console.log(error)
            );
        }
    }, [invoiceId]);


    const cancelDuplicate = () => {
        history.push(`/invoices/${invoiceId}/edit`);
    }

    const onSubmitInvoice = data => {
        if (isValid) {
            setErrorMessage("")
            if (data.netAmount === 0 || data.netAmount === undefined) {
                setErrorMessage(
                    "Montant invalide, montant doit etre supérieur strictement à 0 € "
                );
                return;
            }
            setLoadingModal(true)
            console.log("Save invoice : ", mapData(data));
            console.log(JSON.stringify(mapData(data)));

            service.put(
                getHostByAppName(BILLING_NAME),
                `${context}/cancel_duplicate/${invoice.id}`,
                callbackRes,
                callbackError,mapData(data)
            );

        } else {
            console.log("is not valid");
        }
    };

    const callbackRes = res => {
        console.log(res.data);
        setLoadingModal(false)
        history.push(`/invoices/${res.data.id}/edit`);
    };

    const mapData = data => {
        console.log("invoice data", data);
        return {
            ...data,
            status: [],
            employee: invoice.employee,
            assignment: invoice.assignment,
            client: {
                client:data.client.client,
                name: data.client.name,
                web: data.client.web,
                description: data.client.description,
                actif: data.client.actif,
                bankDetails: data.client.bankDetails,
                contact: data.client.contact,
                entity: data.client.entity,
                addressBilling: data.client.addressBilling,
                administrationInformation: data.client.administrationInformation,
                contactInformation: data.client.contactInformation
            },
            transmitter: {
                organization:data.transmitter.organization,
                name: data.transmitter.name,
                web: data.transmitter.web,
                description: data.transmitter.description,
                address: data.transmitter.address,
                bankDetails: data.transmitter.bankDetails,
                contactInformation: data.transmitter.contactInformation,
                administrationInformation: data.transmitter.administrationInformation
            }
        }
    }

    return (
        <div>
            <div>
                <Row>
                    <Col className="col-sm-12">
                        <div>

                                <h4 className="mb-0 fs-15 bold-text" style={{color:"#FF0B0E"}}>Création suite à l'annulation totale de la facture N° {invoice.id}</h4>

                        </div>
                    </Col>
                </Row>

                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style">
                            <div className="roe-card-body col-sm-12">


                                <br/>
                                <form onSubmit={handleSubmit(onSubmitInvoice)}>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <div className="display-5 c-text-danger">
                                                {errorMessage}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="ptb-15">
                                        <Col className="col-sm-6">
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-3 pb-3">
                                            <InvoiceDate
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                                edit={true}
                                            />
                                            <InvoiceDisplayTransmitter
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}
                                            />
                                                </div></PageviewsChartWrapper>
                                        </Col>
                                        <Col className="col-sm-6">
                                            <InvoiceDisplayClient
                                                register={register}
                                                setValue={setValue}
                                                invoice={invoice}

                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-1 pb-4 pr-5 mt-3">
                                            <InvoiceObject
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={true}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoiceLines
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={true} showTotalSettled={true}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <PageviewsChartWrapper>
                                                <div className="page-view-chart-widget-card pl-4 pt-4 pb-4 pr-5 mt-3">
                                            <InvoiceNote
                                                errors={errors}
                                                register={register}
                                                setValue={setValue}
                                                reset={reset}
                                                invoice={invoice}
                                                edit={edit}
                                            />
                                                </div>
                                            </PageviewsChartWrapper>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="col-sm-8"/>
                                        <Col>
                                             <FormGroup>
                                                <AdaButton className="c-btn c-danger w-100" onClick={() => cancelDuplicate()}>
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>

                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <AdaButton className="c-btn c-warning w-100"
                                                           type="submit"
                                                           onClick={() => setIsValid(true)}
                                                           style={{marginLeft: 10}}
                                                >
                                                    <IntlMessages id="action.common.submit"/>
                                                </AdaButton>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Modal
                                        show={loadingModal}
                                        size="sm"
                                        centered
                                        backdrop={"static"}
                                    >
                                        <div className="align-self-center ">
                                            <Row className="pt-3">
                                                <Col>
                                                    <Spinner animation="border" color="warning" role="status"
                                                             style={{width: '5rem', height: '5rem'}}/>
                                                </Col>
                                            </Row>
                                            <Row className="pt-3 pb-2"><Col>
                                                <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                                    Loading ...
                                                </div>
                                            </Col></Row>
                                        </div>
                                    </Modal>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}
